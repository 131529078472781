import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Typography, Button, Paper } from '@material-ui/core';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Link } from 'react-router-dom';



export default function EventListItem(props) {
    const e = props.event;

    const { i18n } = useLingui();


    return (
        <ListItem key={e.event_id}>

            <ImageContainer to={`/event/${e.event_id}`}>
                {e.image_url !== null && (
                    <Image
                        alt={e.event_title}
                        src={e.image_url}
                    />
                )}
            </ImageContainer>

            <Content>

                <Body>

                    <Title>{e.event_title}</Title>

                    {props.includeHostLink ? (
                        <>
                            <HostLink to={`/host/${e.host_id}`}>{e.host_name}</HostLink>
                            <CitySmall>{e.city}, {e.prov_state}</CitySmall>
                        </>
                    ) : (
                        <CityLarge>{e.city}, {e.prov_state}</CityLarge>
                    )}

                </Body>

                <Footer>
                    <Typography>{i18n.date(e.start_date.replace(/-/g, "/"), { dateStyle: 'medium' })}</Typography>
                    <Link style={{textDecoration: 'none'}} to={`/event/${e.event_id}`}>
                        <Action color='secondary' variant='outlined'><Trans>Buy Tickets</Trans></Action>
                    </Link>
                </Footer>

            </Content>


        </ListItem>
    );
}

const PageHeader = styled(Typography)(({ theme }) => ({
    fontSize: '2.4rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(10),
}));
PageHeader.defaultProps = { variant: 'h1' };

const ListItem = styled(Paper)(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(6),
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
    }
}));

const ImageContainer = styled(Link)(({ theme }) => ({
    border: '1px solid #eee',
    lineHeight: 0,
    [theme.breakpoints.up('lg')]: {
        textDecoration: 'none',
        flex: '0 0 180px',
        backgroundColor: theme.palette.background.default,
        height: 240,
        width: 480,
        flexBasis: 480,
        borderTopLeftRadius: theme.spacing(2),
        borderBottomLeftRadius: theme.spacing(2),
        '&:hover': {
            cursor: 'pointer'
        },
    },
    [theme.breakpoints.only('md')]: {
        backgroundColor: theme.palette.background.default,
        height: 210,
        width: 420,
        flexBasis: 420,
        borderTopLeftRadius: theme.spacing(2),
        borderBottomLeftRadius: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
        borderTopLeftRadius: theme.spacing(2),
        borderTopRightRadius: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        width: '100%',
    }
}));

const Image = styled('img')(({ theme }) => ({
    aspectRatio: 2/1,
    width: '100%',
    objectFit: 'contain',
    [theme.breakpoints.up('md')]: {
        borderTopLeftRadius: theme.spacing(2),
        borderBottomLeftRadius: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
        borderTopLeftRadius: theme.spacing(2),
        borderTopRightRadius: theme.spacing(2),
    }
}));

const Content = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    padding: theme.spacing(2)
}));

const Body = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flex: 1
});

const Title = styled(Typography)(({ theme }) => ({
    fontSize: '1.6rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(0.5)
}));
Title.defaultProps = { variant: 'h2' };

const HostLink = styled(Link)(({ theme }) => ({
    ...theme.typography.body1,
    color: theme.palette.grey[700],
    fontSize: '1rem',
    marginBottom: theme.spacing(0.5),
    textDecoration: 'underline'
}));

const CitySmall = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[700],
}));
CitySmall.defaultProps = { variant: 'caption' };

const CityLarge = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[700],
}));
CityLarge.defaultProps = { variant: 'body1' };

const Footer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2)
}));

const Action = styled(Button)(({ theme }) => ({
    marginLeft: '12px',
    borderColor: theme.palette.info.dark,
    color: theme.palette.info.dark,
}));