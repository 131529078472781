import dayjs from "dayjs";


export function generateProductCart(products, timezone) {
    let accessCodeEnabled = false;

    const cart = products
        .filter(p => {
            // Remove products out of sale period
            if (p.sale_start_date && p.sale_end_date) {
                if (dayjs().tz(timezone) < dayjs(p.sale_start_date).tz(timezone, true)) {
                    return false;
                }
                if (dayjs().tz(timezone) > dayjs(p.sale_end_date).tz(timezone, true)) {
                    return false;
                }
            }
            // Remove products locked via access codes (display = 0)
            if (p.display === 0) {
                accessCodeEnabled = true;
                return false;
            }

            // Show this product on the storefront
            return true;
        })
        .map(p => ({
            id: p.product_id,
            type: p.prod_type_id,
            name: p.prod_name,
            description: p.prod_desc,
            minPurchase: p.qty_min_purchase,
            maxPurchase: p.qty_max_purchase,
            available: p.qty_left,
            price: p.price,
            qty: 0,
            paidProductCount: p.paid_product_count, // Either 1 or 0 for individual tickets
            originalValue: null, // Applies to bundles only
            hasCode: Boolean(p.has_code),
            isAttendee: Boolean(p.is_attendee)
        }));

        return {
            cart: cart,
            accessCodeEnabled: accessCodeEnabled
        };
}

export function generateBundleCart(bundles, timezone) {
    let accessCodeEnabled = false;

    const cart = bundles
        .filter(b => {
            // Remove bundles out of sale period
            if (b.sale_start_date && b.sale_end_date) {
                if (dayjs().tz(timezone) < dayjs(b.sale_start_date).tz(timezone, true)) {
                    return false;
                }
                if (dayjs().tz(timezone) > dayjs(b.sale_end_date).tz(timezone, true)) {
                    return false;
                }
            }

            // Remove bundles locked via access codes (display = 0)
            if (b.display === 0) {
                accessCodeEnabled = true;
                return false;
            }

            // Show this bundle on the storefront
            return true;
        })
        .map(b => ({
            id: b.bundle_id,
            name: b.bundle_name,
            description: b.bundle_desc,
            minPurchase: b.qty_min_purchase,
            maxPurchase: b.qty_max_purchase,
            available: b.qty_left,
            price: b.price,
            qty: 0,
            bundleProductsAvailable: b.bundle_products_available, // Applies to bundles only
            paidProductCount: b.paid_product_count,
            originalValue: b.original_value, // Applies to bundles only
            hasCode: Boolean(b.has_code),
            products: b.products.map(bp => ({
                id:         bp.product_id,
                name:       bp.prod_name,
                qty:        bp.qty,
                isAttendee: Boolean(bp.is_attendee)
            }))
        }))

        return {
            cart: cart,
            accessCodeEnabled: accessCodeEnabled
        };
}