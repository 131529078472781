import { createTheme } from '@material-ui/core/styles';
import indigo from "@material-ui/core/colors/indigo";


export const theme = createTheme({
    palette: {
        primary: {
            main: '#39E991'
        },
        secondary: {
            main: '#2FA3F2'
        },
        background: {
            main: '#262626'
        }
    },
});

export const datePickerTheme = createTheme({
    palette: {
      secondary: {
          main: '#2FA3F2'
      },
    },
    overrides: {
        MuiPickersToolbar: {
          toolbar: {
            backgroundColor: indigo.A200,
          },
        },
        MuiPickersDay: {
          day: {
            color: indigo.A700,
          },
          daySelected: {
            backgroundColor: indigo["400"],
          },
          dayDisabled: {
            color: indigo["100"],
          },
          current: {
            color: indigo["900"],
          },
        },
      },
      MuiButton: {
        text: {
            color: indigo.A700
        }
      }
})