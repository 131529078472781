import React from 'react';


// Material-UI
import { styled, AppBar as _AppBar, Toolbar, Button, Box } from '@material-ui/core';

// Utility
import { useLingui } from '@lingui/react';

export default function MainAppBar() {
    const { i18n } = useLingui();

    const nextLocale = i18n.locale === 'en' ? 'fr' : 'en';
    const nextLocaleText = i18n.locale === 'en' ? 'FR' : 'EN';

    const handleLocaleChange = () => {
        i18n.activate(nextLocale);
        localStorage.setItem('store_lang', nextLocale);
    };

    return (
        <AppBar position='sticky'>
            <Toolbar>
                <Box display='flex' width='100%' justifyContent='space-between'>
                    <a href='https://frontdoor.plus'>
                        <img src={`${process.env.REACT_APP_STOREFRONT_S3_LOCATION}/web/frontdoor-plus-logo-wide-white.svg`} alt='' style={{height: 36}}/>
                    </a>
                    <Button color='secondary' variant='outlined' style={{marginRight: 16}} onClick={handleLocaleChange}>{nextLocaleText}</Button>
                </Box>
            </Toolbar>
        </AppBar>
    )
}


const AppBar = styled(_AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.main,
}));