import React from 'react';

// Material-UI
import { makeStyles } from '@material-ui/core';
import { Tooltip, IconButton } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export default function InfoTooltip(props) {
    const { message } = props;
    const c = useStyles();

    return (
        <Tooltip arrow classes={{ tooltip: c.tooltipMessage, arrow: c.tooltipArrow }} disableFocusListener placement='top' enterTouchDelay={0} leaveTouchDelay={5000} title={message}>
            <IconButton className={c.tooltipIcon}>
                <InfoOutlinedIcon />
            </IconButton>
        </Tooltip>
    )
}


const useStyles = makeStyles(() => ({
    tooltipIcon: {
        padding: 0
    },
    tooltipMessage: {
        backgroundColor: '#0092d3',
        fontSize: '1rem'
    },
    tooltipArrow: {
        color: '#0092d3'
    }
}))