/**
 * When the user navigates to a new page, we run this function to update the page's meta data to reflect the content being shown. This helps with SEO and link previews.
 * 
 * We set the following meta data tags:
 *  - Standard html title, description
 *  - Open Graph tags (Open standard used by facebook and others)
 *  - Twitter tags
 * 
 * IMPORTANT / TODO: Crawlers from facebook, twitter, linkedin etc. are not interpreting javascript and only check the html file sent from events.frontdoor.plus for meta tags. They will not get the values we update dynamically thru this function.
 *                   We're still using this code in case they start checking javascript.
 *                   Most likely, we'll need to move to server-side rendering to get these meta tags recognized by crawlers
 */
export function setPageMetadata(metadata) {

    const title = metadata?.title || 'Get your tickets today';
    const description = metadata?.description || "Stay informed about our upcoming events! Discover exciting experiences. Our events offer a range of activities and experiences that cater to various interests. Don't miss out on getting tickets and the chance to expand your horizons and network with others in your community.";
    const image = metadata?.image || "";

    const url = document.location.href;


    // *** Page URL *** //
    document.querySelectorAll('[property="og:url"]')[0].content = url; // Open Graph
    document.querySelectorAll('[property="twitter:url"]')[0].content = url; // Open Graph

    // *** Page Title *** //
    document.title = title; // HTML
    document.querySelectorAll('[property="og:title"]')[0].content = title; // Open Graph
    document.getElementsByTagName('meta')['twitter:title'].content = title; // Twitter

    // *** Page Description *** //
    document.getElementsByTagName('meta')['description'].content = description; // HTML
    document.querySelectorAll('[property="og:description"]')[0].content = description; // Open Graph
    document.getElementsByTagName('meta')['twitter:description'].content = description; // Twitter

    // *** Image *** //
    document.querySelectorAll('[property="og:image"]')[0].content = image; // Open Graph
    document.getElementsByTagName('meta')['twitter:image'].content = image; // Twitter
}