import React, { createContext, useContext, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';



// Context holds all state related to the display of Alert messages
export const NotificationContext = createContext();


// Hook allows react components to access Notification data and functions
export function useNotification() {
  return useContext(NotificationContext);
}


// Provider wraps the App component with the Notification Context
// Any child react components will be able to hook into this data
export function NotificationProvider(props) {
    // State
    const [notification, setNotification] = useState({ open: false, message: '', variant: 'info' });

    // Setters
    function createNotification(message, options) {
        const variant = options?.variant || 'info';

        setNotification({ open: true, message, variant: variant });
    }

    function closeNotification() {
        setNotification(s => ({ ...s, open: false, message: '' }));
    }

    return (
        <NotificationContext.Provider value={{ notification, createNotification, closeNotification }}>
            {props.children}
        </NotificationContext.Provider>
    )
}


// Notification Component: Displays an alert when notification is activated, and a message is provided
export function Notification() {
    const { notification, closeNotification } = useNotification();

    return (
        <Snackbar
            open={notification.open}
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            autoHideDuration={5000}
            onClose={closeNotification}
        >
            <Alert severity={notification.variant} onClose={closeNotification} variant='filled'>{notification.message}</Alert>
        </Snackbar>
    )
}
