import React from 'react';

// Material UI
import { Dialog, AppBar, Toolbar, IconButton, Slide, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenModal(props) {
    const { open, onClose, title } = props;

    function handleClose(e, reason) {
        if (reason !== 'backdropClick') {
            onClose();
        }
    }

    return (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar position='sticky' style={{backgroundColor: 'white'}}>
                <Toolbar>
                    <IconButton edge="start" style={{color:"black"}} onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Box position='absolute' style={{left: '50%', transform: 'translate(-50%,0)'}}>
                        <Box fontSize='1.5rem'>{title}</Box>
                    </Box>
                </Toolbar>
            </AppBar>
            {open && props.children}
        </Dialog>
    )
}