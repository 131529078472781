import React from 'react';

// Material-UI
import { styled, Box, AppBar as _AppBar, Toolbar, Button, Hidden } from '@material-ui/core';

// Utility
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

export default function CheckoutAppBar(props) {
    const { subTotal, onClick, disabled } = props;

    const { i18n } = useLingui();

    const nextLocale = i18n.locale === 'en' ? 'fr' : 'en';
    const nextLocaleText = i18n.locale === 'en' ? 'FR' : 'EN';

    const handleLocaleChange = () => {
        i18n.activate(nextLocale);
        localStorage.setItem('store_lang', nextLocale);
    };

    return (
        <AppBar position='sticky'>
            <Toolbar>
                <Hidden xsDown>
                    <a href='https://frontdoor.plus' target="_blank" rel="noopener noreferrer">
                        <img src={`${process.env.REACT_APP_STOREFRONT_S3_LOCATION}/web/frontdoor-plus-logo-wide-white.svg`} alt='' style={{height: 36}}/>
                    </a>
                </Hidden>
                <Box color='white' mr={2} ml='auto'>{`Subtotal: ${i18n.number(subTotal, { style: 'currency', currency: 'CAD', currencyDisplay: 'narrowSymbol' })}`}</Box>
                <Button color='secondary' variant='outlined' style={{marginRight: 16}} onClick={handleLocaleChange}>{nextLocaleText}</Button>
                <CheckoutButton color='primary' variant={disabled ? 'outlined' : 'contained'} onClick={onClick} disabled={disabled}><Trans>Checkout</Trans></CheckoutButton>
            </Toolbar>
        </AppBar>
    )
}


const AppBar = styled(_AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.main
}));

const CheckoutButton = styled(Button)(({ theme }) => ({
    '&.Mui-disabled': {
        backgroundColor: undefined,
        color: '#f0f0f0b3',
        borderColor: '#f0f0f0b3',
    }
}));