import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { Notification } from 'utility/notification';

// Pages
import LandingPage from 'pages/LandingPage';
import EventPage from 'pages/EventPage';
import HostPage from 'pages/HostPage';
import TicketLookupPage from 'pages/TicketLookupPage';
import PageNotFound from 'pages/PageNotFound';
import { setPageMetadata } from 'pageMetadata';

export default function App() {
    return (
        <>
            <PageTitle />

            <Routes>

                <Route path="/" element={<LegacyRouter />} />

                <Route path="/event" element={<EventPage />}>
                    <Route path=":eventId" element={<EventPage />} />
                </Route>

                <Route path="/host" element={<HostPage />}>
                    <Route path=":hostId" element={<HostPage />} />
                </Route>

                <Route path="/ticket_lookup" element={<TicketLookupPage />} />

                <Route path='*' element={<PageNotFound />} />

            </Routes>
            
            <Notification />
        </>
    )
}


/**
 * Updates the title and description tags whenever the user navigates to a new page
 * 
 * Note: This is only for static pages. Host and event screens will dynamically set these tags based on the host/event being viewed
 * 
 * Note: Web crawlers don't seem to be interpreting javascript so these values not not likely being used.
 *       We'll keep updating the tab in case that changes, but we may have to move to server-side rendering at some point.
 */
function PageTitle() {
    const location = useLocation();

    useEffect(() => {
        switch (location.pathname) {
            case '/':
                setPageMetadata(null); // Reset to default meta data
                break;
            case '/ticket_lookup':
                setPageMetadata({
                    title: 'Find your tickets',
                    description: 'Lost your tickets? You can find your tickets using the form below.',
                    image: null
                });
                break;
            default:
                // Event and Host pages set these values themselves based on the event/host being viewed
                break;
        }
    }, [location.pathname]);
    
    return null;
}


// Handle old routes that were phased out in favour of the hash routing above
// Before, we just rendered pages based on query params in the url. These links could still be out in the wild

function LegacyRouter() {
    const [ready, setReady] = useState(false);

    const navigate = useNavigate();


    useEffect(() => {
        const url = new URL(document.location);
    
        const hash = url.hash;
        const event = url.searchParams.get('e');
        const host = url.searchParams.get('o');
    
        if (hash) {
            navigate(hash.replace('#','') + url.search, { replace: true });
        }
    
        else if (event) {
            url.searchParams.delete('e');
            url.searchParams.delete('o');
            navigate(`/event/${event}` + url.search, { replace: true });
        }
    
        else if (host) {
            url.searchParams.delete('e');
            url.searchParams.delete('o');
            navigate(`/host/${host}` + url.search, { replace: true });
        }
    
        else {
            setReady(true);
        }
        // eslint-disable-next-line
    }, []);


    if (ready) {
        return <LandingPage />;
    } else {
        return null;
    }
}