import React, { useEffect } from 'react';

// Material-UI
import { Box, Card, CardContent, Button, Typography, styled } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';

// Components
import ReceiptConfirmation from "./ReceiptConfirmation";

// Images
import checked_icon from 'images/checked_icon.png';

// Utilities
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Link } from 'react-router-dom';


const AppleWalletEN = 'https://frontdoorplus.s3.ca-central-1.amazonaws.com/storefront-images/web/apple-wallet-e.png';
const AppleWalletFR = 'https://frontdoorplus.s3.ca-central-1.amazonaws.com/storefront-images/web/apple-wallet-f.png';
const GoogleWalletEN = 'https://frontdoorplus.s3.ca-central-1.amazonaws.com/storefront-images/web/google-wallet-e.png';
const GoogleWalletFR = 'https://frontdoorplus.s3.ca-central-1.amazonaws.com/storefront-images/web/google-wallet-f.png';



export default function PurchaseSuccess(props) {
    const { email, products, bundles, event, ticketCount, paidTransaction, discount, appleWalletURL, googleWalletURL, onReturn } = props;
    const { ctaDescription, ctaButtonText, ctaButtonURL } = event;

    const ctaAvailable = (ctaDescription && ctaButtonText && ctaButtonURL);

    // Apple wallet will fail to open if there are more than 10 tickets included
    const showAppleWallet = ticketCount <= 10;

    const { i18n } = useLingui();

    // Reset scroll position to top after component renders
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <Root>
            <Box p={[1, 0]}>

                <Box width='100%' mt={8} mb={8} display='flex' justifyContent='center' alignItems='center'>
                    <img src={checked_icon} alt='' style={{width: '32px', height: '32px'}}/>
                    <Header variant='h4'><Trans>Your order is complete!</Trans></Header>
                </Box>

                
                <Section>
                    <CardContent>
                        <SupportText><Trans>Your tickets and receipt will be emailed to:</Trans></SupportText>
                        <Email variant='h5'>{email}</Email>
                    </CardContent>
                </Section>

                <Section>
                    <CardContent>
                        <SupportText><Trans>For added convenience, you can save your tickets to your wallet.</Trans></SupportText>

                        <DownloadButtonContainer>

                            <WalletContainer>
                                {showAppleWallet && (
                                    <WalletButton href={appleWalletURL} target="_blank" rel="noopener noreferrer">
                                        <WalletImage src={i18n.locale === 'fr' ? AppleWalletFR : AppleWalletEN} alt={t`Add to Apple Wallet`} />
                                    </WalletButton>
                                )}
                                <WalletButton href={googleWalletURL} target="_blank" rel="noopener noreferrer">
                                    <WalletImage src={i18n.locale === 'fr' ? GoogleWalletFR : GoogleWalletEN} alt={t`Add to Google Wallet`} />
                                </WalletButton>
                            </WalletContainer>

                        </DownloadButtonContainer>

                        {showAppleWallet && (
                            <AppleWalletText variant='caption' display='block'><Trans>To add tickets to your Apple Wallet, you must be using Safari on an iPhone, iPad, or Mac.</Trans></AppleWalletText>
                        )}
                    </CardContent>
                </Section>

                <Section>
                    <CardContent>
                        <ReceiptConfirmation
                            products={products}
                            bundles={bundles}
                            event={event}
                            paidTransaction={paidTransaction}
                            discount={discount}
                        />
                    </CardContent>                    
                </Section>
            
                {ctaAvailable
                    ? (
                        <Section>
                            <CardContent>
                                <CtaText variant='h6'>{ctaDescription}</CtaText>
                                <CtaButtonContainer>
                                    <CtaButton variant='contained' href={ctaButtonURL} startIcon={<LaunchIcon />}>{ctaButtonText}</CtaButton>
                                </CtaButtonContainer>
                            </CardContent>
                        </Section>
                    )
                    : (
                        <Section>
                            <CardContent>
                                <CtaText variant='h6'>{t`See more events from` + ' ' + event.hostName}</CtaText>
                                <CtaButtonContainer>
                                    <CtaLink to={`/host/${event.hostId}`}>
                                        <Button fullWidth variant='contained'><Trans>View Events</Trans></Button>
                                    </CtaLink>
                                    <ContinueButton variant='outlined' onClick={onReturn}><Trans>Continue Shopping</Trans></ContinueButton>
                                </CtaButtonContainer>
                            </CardContent>
                        </Section>
                    )
                }

                <Section>
                    <CardContent>
                        <SupportText>
                            <Trans>If you have any questions or issues, please reach out to us using the Chat app on our website (https://frontdoor.plus) or drop us an email at hello@frontdoorplus.com.</Trans>
                        </SupportText>
                    </CardContent>
                </Section>

            </Box>
        </Root>
    )
}

const Root = styled('div')(({ theme }) => ({
    maxWidth: theme.breakpoints.values.sm,
    marginLeft: 'auto',
    marginRight: 'auto'
}));

const Header = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    marginLeft: theme.spacing(1),
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
        fontSize: 20
    },
}));

const Email = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: theme.spacing(2)
}));

const Section = styled(Card)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(2)
}));

const AppleWalletText = styled(Typography)(({ theme }) => ({
    textAlign: 'center'
}));

const SupportText = styled(Typography)(({ theme }) => ({
    textAlign: 'center'
}));

const CtaText = styled(Typography)({
    marginBottom: 24,
    marginLeft: 16,
    marginRight: 16,
    textAlign: 'center'
});

const CtaButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}));

const CtaButton = styled(Button)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        width: '70%'
    },
    [theme.breakpoints.up('md')]: {
        width: '50%',
    }
}));

const WalletContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around'
}));

const WalletButton = styled('a')(({ theme }) => ({
    height: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
        height: theme.spacing(6)
    }
}));

const WalletImage = styled('img')(({ theme }) => ({
    height: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
        height: theme.spacing(6)
    }
}));

const DownloadButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center'
}));

const ContinueButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        width: '70%'
    },
    [theme.breakpoints.up('md')]: {
        width: '50%',
    }
}));

const CtaLink = styled(Link)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        width: '70%'
    },
    [theme.breakpoints.up('md')]: {
        width: '50%',
    }
}));