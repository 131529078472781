import React from 'react';

// Material-UI
import { useTheme } from '@material-ui/core/styles';
import { Box, styled, Typography, Divider } from '@material-ui/core';

// Components
import Product from 'components/Product';

import { Trans } from '@lingui/macro';


export default function ProductList(props) {
    const { products, bundles, chargesEnabled, onAddProduct, onRemoveProduct, onAddBundle, onRemoveBundle } = props;
    const theme = useTheme();

    const admissions = products.filter((p) => p.type === 1)
    const addons = products.filter((p) => p.type === 2)

    return (
        <Box px={[2,4]} pb={[8]} mx={[0,0,0,'auto']} maxWidth={theme.breakpoints.values.lg}>

            {bundles.length > 0 && (
                <>
                    <Header><Trans>Bundles</Trans></Header>
                    <HeaderDivider />
                </>
            )}

            {bundles.map((b) => (
                <Product
                    key={b.id}
                    product={b}
                    chargesEnabled={chargesEnabled}
                    onAdd={onAddBundle}
                    onRemove={onRemoveBundle}
                />
            ))}

            <Box mb={8}/>
            
            {admissions.length > 0 && (
                <>
                    <Header><Trans>Tickets</Trans></Header>
                    <HeaderDivider />
                </>
            )}

            {admissions.map((p) => (
                <Product
                    key={p.id}
                    product={p}
                    chargesEnabled={chargesEnabled}
                    onAdd={onAddProduct}
                    onRemove={onRemoveProduct}
                />
            ))}

            <Box mb={8}/>

            {addons.length > 0 && (
                <>
                    <Header variant='h4'><Trans>Add-ons</Trans></Header>
                    <HeaderDivider />
                </>
            )}

            {addons.map((p) => (
                <Product
                    key={p.id}
                    product={p}
                    chargesEnabled={chargesEnabled}
                    onAdd={onAddProduct}
                    onRemove={onRemoveProduct}
                />
            ))}
        </Box>
    )
}

const Header = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: '2.1rem',
}));
Header.defaultProps = { variant: 'h2' };

const HeaderDivider = styled(Divider)(({ theme }) => ({
    marginTop: theme.spacing(1),
    //height: '2px',
    backgroundColor: theme.palette.common.white,
    width: '100%',
    marginBottom: theme.spacing(4),
}));