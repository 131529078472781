import React, { useState } from 'react';
import { styled, Box, Button, Paper, TextField, Typography, CircularProgress } from '@material-ui/core';
import { verifyAccessCode } from 'utility/requests';
import { useNotification } from 'utility/notification';
import { Trans, t } from '@lingui/macro';


export default function AccessCodeForm(props) {
    const { eventId, accessCodes, onSubmit } = props;
    
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const { createNotification } = useNotification();


    const handleSubmit = () => {
        // Make sure code hasn't already been used
        const used = accessCodes.some(c => c.toLowerCase() === code.toLowerCase());
        if (used === true) {
            setError(t`This code has already been used`);
            return;
        }

        setLoading(true);

        verifyAccessCode(code, eventId)
            .then(ac => {
                if (ac.valid === true) {
                    // Code is valid. Attempt to reload products with new access code applied.
                    onSubmit(code);
                    setError(null);
                    createNotification(t`Access Code Applied`, { variant: 'success' });
                } else {
                    setError(t`This code is not valid`);
                }
            })
            .catch(error => {
                setError(t`There was a problem applying your access code`);
            })
            .finally(() => setLoading(false))
    };

    const handleFormChange = (e) => {
        // Format input: Alphanumeric only. No spaces. Max 25 characters.
        const value = e.target.value.replace(/[^0-9a-zA-Z]/g,'').slice(0,25);
        setCode(value);
        setError(null);
    };

    const handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <Box width='100%' display='flex' justifyContent='center' pt={2}>
            <Container>
                <Title>
                    <Trans>Have an Access Code?</Trans>
                </Title>
                <Box display='flex' alignItems='flex-start'>
                    <AccessCodeField
                        value={code}
                        disabled={loading}
                        error={Boolean(error)}
                        helperText={error}
                        onChange={handleFormChange}
                        onKeyUp={handleKeyUp}
                    />
                    <UnlockButton
                        disabled={loading || code.length === 0}
                        onClick={handleSubmit}
                        startIcon={loading ? <CircularProgress size={14} color='inherit' /> : undefined}
                        >
                            <Trans>Unlock</Trans>
                    </UnlockButton>
                </Box>
            </Container>
        </Box>
    )
}


const Container = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
}));

const Title = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(1)
}));

const AccessCodeField = styled(TextField)(({ theme }) => ({
    marginRight: theme.spacing(2)
}));
AccessCodeField.defaultProps = { variant: 'outlined', size: 'small', color: 'secondary' }

const UnlockButton = styled(Button)(({ theme }) => ({
    height: '40px',
    width: '120px'
}));
UnlockButton.defaultProps = { variant: 'outlined', color: 'secondary' }