
// We use this toggle to enable/disable GA4 loggin as a whole.
// Later we should look at setting up dev/prod environments, and moving any env variables to config.json
const ANALYTICS_ENABLED = process.env.REACT_APP_ENV === 'production';

// Google Analytics Configuration
// Note: gtag script must be loaded in the <head> of index.html first

if (ANALYTICS_ENABLED) {
    window.dataLayer = window.dataLayer || [];

    window.gtag = function() {
        window.dataLayer.push(arguments);
    }

    window.gtag('js', new Date());
    window.gtag('config', 'G-8JHQC12NZF', { send_page_view: false });
}



/**
 * GA4 page_view event. Only fires in production.
 * @param {string} title
 * @param {string} path
 */
export function pageView(title, path) {
    try {
        if (ANALYTICS_ENABLED) {
            const pageOrigin = window.location.origin;
            const pagePath = path || window.location.hash.substring(1);
            const pageSearch = window.location.search;
            
            window.gtag('event', 'page_view', {
                page_location: pageOrigin + pageSearch + pagePath,
                page_title: title
            })
        }
    }
    catch(e) { return; }
}

/**
 * GA4 exception event. Only fires in production.
 * @param {string} description
 * @param {boolean} fatal
 */
export function exception(description, fatal) {
    try {
        if (ANALYTICS_ENABLED) {
            window.gtag('event', 'exception', {
                description: description,
                fatal: typeof fatal === 'boolean' ? fatal : false
            });
        }
    }
    catch(e) { return; }
}

/**
 * GA4 purchase event (ecommerce). Only fires in production.
 * @param {number} id
 * @param {number} total
 * @param {number} tax
 * @param {string} coupon
 */
export function purchase(id, total, tax, coupon) {
    try {
        if (ANALYTICS_ENABLED) {
            window.gtag("event", "purchase", {
                transaction_id: String(id),
                value: String(total),
                tax: String(tax),
                currency: "CAD",
                coupon: typeof coupon === 'string' ? coupon.toUpperCase() : undefined,
            })
        }
    }
    catch(e) { return; }
}