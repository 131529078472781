import { createContext, useContext } from 'react';


export const EventContext = createContext();
export const SetEventContext = createContext();

export function useEvent() {
    return useContext(EventContext);
}

export function useSetEvent() {
    return useContext(SetEventContext);
}