import React, { useState, useEffect } from 'react';
import { styled, Box, Button, CircularProgress, Typography} from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import CustomField from './CustomField'
import dayjs from 'dayjs';
import { useNotification } from 'utility/notification';
import { t, Trans } from '@lingui/macro';

/*

Old Setup:
    sessionData: {
        eventId,
        lastUpdated,
        form: [ { id, datatype, value } ]
    }

New Setup:
    sessionData: {
        eventId,
        lastUpdated,
        attendees: [
            {
                productId,
                productName,
                form: [ { id, datatype, value } ]
            }
        ]
    }

*/

export default function Registration(props) {
    const { mode, questions, products, bundles, onSubmit, onCancel } = props;

    const [state, setState] = useState({
        status: 'loading',
        attendees: []
    });

    const { createNotification } = useNotification();

    useEffect(() => {
        async function init() {
            try {
                // ** Generate Attendee List ** //
                let attendees = [];

                if (mode === 1) {
                    // By Order: We pass in the IDs of all tickets in the users cart
                    // This will be used if there are questions that can only be answered when buying specific tickets
                    const productIds = new Set();

                    for (const p of products) {
                        if (p.qty > 0) {
                            productIds.add(p.id)
                        }
                    }
                    for (const b of bundles) {
                        if (b.qty > 0) {
                            for (const bp of b.products) {
                                productIds.add(bp.id)
                            }
                        }
                    }

                    attendees.push({
                        tempId: 1,
                        productId: null,
                        productName: null,
                        form: initializeForm(questions, Array.from(productIds))
                    })
                }
                else if (mode === 2) {
                    // By Attendee: We pass in just the ID of the attendee ticket. Not the other tickets in the order.
                    for (const p of products) {
                        if (p.qty > 0 && p.isAttendee === true) {
                            for (let i = 0; i < p.qty; i++) {
                                attendees.push({
                                    //tempId is assinged later
                                    productId: p.id,
                                    productName: p.name,
                                    form: initializeForm(questions, [p.id])
                                })
                            }
                        }
                    }
                    // By Attendee: We pass in just the ID of the attendee ticket. Not the other tickets in the order.
                    for (const b of bundles) {
                        for (const bp of b.products) {
                            if (bp.isAttendee === true) {
                                const pCount = b.qty * bp.qty;
                                for (let i = 0; i < pCount; i++) {
                                    attendees.push({
                                        //tempId is assinged later
                                        productId: bp.id,
                                        productName: bp.name,
                                        form: initializeForm(questions, [bp.id])
                                    })
                                }
                            }
                        }
                    }

                    attendees = attendees.sort((a,b) => a.productId - b.productId);

                    // Assign tempId values after the attendees are placed in order by product type
                    for (let i = 1; i <= attendees.length; i++) {
                        attendees[i-1].tempId = i;
                    }
                }

                setState(s => ({
                    ...s,
                    status: 'success',
                    attendees: attendees
                }))

            } catch(e) {
                setState(s => ({ ...s, status: 'error' }))
            }
        }
        init();
    // eslint-disable-next-line
    }, []);


    const handleAttendeeUpdate = (value) => {
        const attendees = state.attendees.map(a => {
            if (a.tempId === value.tempId) {
                return value;
            } else {
                return a;
            }
        });

        setState(s => ({ ...s, attendees: attendees }));
    };


    // ***** Validate Form Values ***** //

    const handleValidation = () => {

        let formError = false;

        const newAttendeeList = [];


        // Map over every attendee form
        for (const attendee of state.attendees) {

            let attendeeForm = [];

            // Map over every field, and check for errors
            // Assemble a new form array containing any new error messages
            for (const field of attendee.form) {

                const { error, errorMsg } = fieldValidator(field.value, field.type, field.required);
    
                if (error) {
                    formError = true;
                    attendeeForm.push({ ...field, errorMsg })
                } else {
                    attendeeForm.push(field)
                }
            }

            newAttendeeList.push({ ...attendee, form: attendeeForm });
        }

        
        if (formError === false) {
            submitForm();
        } else {
            setState(s => ({ ...s, attendees: newAttendeeList }));
            createNotification('Please ensure the form is filled in correctly', { variant: 'warning' })
        }
    };


    // ***** Format and Submit Values ***** //

    const submitForm = () => {

        let test = [];

        for (const attendee of state.attendees) {

            const data = attendee.form.reduce((accumulator, field) => {
    
                let value = null;
    
                switch (field.type) {
    
                    case FieldTypes.SHORT_ANSWER:
                    case FieldTypes.PARAGRAPH:
                        if (field.value.trim()) {
                            value = field.value.trim();
                        }
                        break;
    
                    case FieldTypes.SINGLE_CHOICE:
                    case FieldTypes.DROPDOWN:
                        if (field.value) {
                            value = field.value;
                        }
                        break;
    
                    case FieldTypes.AGE:
                        if (field.value) {
                            value = Number(field.value);
                        }
                        break;
    
                    case FieldTypes.FULL_DATE:
                        if (field.value) {
                            value = dayjs(field.value).format('YYYY-MM-DD');
                        }
                        break;
    
                    case FieldTypes.SHORT_DATE:
                        if (field.value) {
                            value = dayjs(field.value).format('YYYY-MM-01'); // Only set the year + month. Day is always 01
                        }
                        break;
    
                    case FieldTypes.MULTIPLE_CHOICE:
                        if (field.value.size) {
                            value = Array.from(field.value);
                        }
                        break;
    
                    case FieldTypes.YES_OR_NO:
                    case FieldTypes.TERMS_AND_CONDITIONS:
                        if (typeof field.value === 'boolean') {
                            value = field.value;
                        }
                        break;
    
                    default:
                        break;
                }
    
                if (value !== null) {
                    accumulator.push({
                        id: field.id,
                        datatype: field.datatype,
                        value: value
                    })
                }
    
                return accumulator;
    
            }, []);

            test.push({
                productId: attendee.productId,
                answers: data
            })
        }

        onSubmit(test)
    };


    if (state.status === 'loading') {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' height='100%' width='100%'>
                <CircularProgress />
            </Box>
        )
    }

    if (state.status === 'error') {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' height='100%' width='100%'>
                <Typography>Unable to open checkout</Typography>
            </Box>
        )
    }

    return (
        <Root>
            <Content>

                {state.attendees.map((attendee) => (
                    <AttendeeForm
                        attendee={attendee}
                        onAttendeeUpdate={handleAttendeeUpdate}
                    />
                ))}

                <Box display='flex' justifyContent='flex-end' mt={4}>
                    <CancelButton onClick={onCancel}><Trans>Cancel</Trans></CancelButton>
                    <SubmitButton onClick={handleValidation} color='primary'><Trans>Continue</Trans></SubmitButton>
                </Box>
                
            </Content>
        </Root>
    )
}


function AttendeeForm(props) {
    const { attendee, onAttendeeUpdate } = props;

    const form = attendee.form;


    // ***** Process Form Value Changes ***** //

    const handleChange = (index, value, optionId) => {
        const newForm = Array.from(form);

        switch (form[index].type) {
            case FieldTypes.AGE:
                newForm[index].value = value.replace(/[^0-9]/g,'').slice(0,3);
                break;

            case FieldTypes.MULTIPLE_CHOICE:
                value === true
                    ? newForm[index].value.add(optionId)
                    : newForm[index].value.delete(optionId)
                break;

            default:
                newForm[index].value = value;
                break;
        }

        newForm[index].errorMsg = null;

        onAttendeeUpdate({ ...attendee, form: newForm });
    };


    return (
        <AttendeeFormContainer>
            <CardHeader
                title={attendee.productName}
                subheader={`Attendee ${attendee.tempId}`}
            />
            <CardContent>
                {form.map((field, index) => (
                    <CustomField
                        key={index}
                        index={index}
                        onChange={handleChange}
                        {...field}
                    />
                ))}
            </CardContent>
        </AttendeeFormContainer>
    )
}


// Format registration questions to be used as form state
function initializeForm(questions, productIds) {
    // Its important that we create a new instance of the form and its fields.
    // This avoids bugs that occur when elements in a complex/deeply nested object share the same references

    // We use a deep clone of the questions list as a base for the form
    const checkoutQuestions = JSON.parse(JSON.stringify(questions));
    const form = [];

    for (const cq of checkoutQuestions) {
        
        const field = cq;

        // Check if this question is limited to certain ticket types
            // If question_products contains product IDs, then there needs to be at least one of those product IDs in the attendee's cart before we can ask this question
            // If questin_products does not contain any product IDs then we ask the question no matter what

        const questionProducts = field.question_products;

        
        if (Array.isArray(questionProducts) && questionProducts.length > 0) {

            let skipQuestion = true;

            for (const p of productIds) {
                for (const qp of questionProducts) {
                    if (p === qp) {
                        // If there's a match then we ask the question
                        skipQuestion = false;
                    }
                }
            }

            // Skip the question
            if (skipQuestion === true) {
                continue;
            };
        }

        // Init errorMsg property
        field.errorMsg = null;

        // Set the field default value
        switch (field.type) {
            case FieldTypes.MULTIPLE_CHOICE:
                field.value = new Set();
                break;

            case FieldTypes.SHORT_ANSWER:
            case FieldTypes.PARAGRAPH:
            case FieldTypes.AGE:
            case FieldTypes.DROPDOWN:
                field.value = '';
                break;

            case FieldTypes.TERMS_AND_CONDITIONS:
                field.value = false;
                break;

            // YES_OR_NO: Neither option is selected by default
            default:
                field.value = null;
                break;
        }
        
        form.push(field);
    }

    return form;
}


function fieldValidator(value, type, required) {
    switch (type) {

        case FieldTypes.SHORT_ANSWER:
        case FieldTypes.PARAGRAPH:
            if (required && value.trim().length === 0) {
                return { error: true, errorMsg: t`This field is required`}
            } else if (value.trim().length > 2000) {
                return { error: true, errorMsg: t`This field cannot contain more than 2000 characters` }
            } else {
                return { error: false, errorMsg: null }
            }

        case FieldTypes.SINGLE_CHOICE: // null if none selected
        case FieldTypes.DROPDOWN: // empty string if none selected
            if (required && !value) {
                return { error: true, errorMsg: t`This field is required`}
            } else {
                return { error: false, errorMsg: null }
            }

        case FieldTypes.MULTIPLE_CHOICE:
            if (required && value.size === 0) {
                return { error: true, errorMsg: t`This field is required` }
            } else {
                return { error: false, errorMsg: null }
            }
        
        case FieldTypes.FULL_DATE:
        case FieldTypes.SHORT_DATE:
            if (required && !value) {
                return { error: true, errorMsg: t`This field is required` }
            }
            if (!required && !value) {
                return { error: false, errorMsg: null }
            }
            else if (dayjs(value).isValid() === false) {
                return { error: true, errorMsg: t`Please enter a valid date` }
            }
            else {
                return { error: false, errorMsg: null }
            }
        
        case FieldTypes.AGE:

            if (isNaN(value)) {
                return { error: true, errorMsg: t`Please enter a valid number` }
            }
            if (required && !value) {
                return { error: true, errorMsg: t`This field is required` }
            }
            if (!required && !value) {
                return { error: false, errorMsg: null }
            }
            if (Number(value) < 1 || Number(value > 120)) {
                return { error: true, errorMsg: t`Please enter a valid number` }
            }
            return { error: false, errorMsg: null }
        
        case FieldTypes.YES_OR_NO:
            if (required && typeof value !== 'boolean') {
                return { error: true, errorMsg: t`This field is required` }
            } else {
                return { error: false, errorMsg: null }
            }

        case FieldTypes.TERMS_AND_CONDITIONS:
            if (required && value !== true) {
                return { error: true, errorMsg: t`This field is required` }
            } else {
                return { error: false, errorMsg: null }
            }

        case FieldTypes.TEXT_BLOCK:
        case FieldTypes.IMAGE_BLOCK:
            return { error: false, errorMsg: null }

        default:
            break;
    }
}


const FieldTypes = {
    'SHORT_ANSWER': 1,
    'PARAGRAPH': 2,
    'SINGLE_CHOICE': 3,
    'MULTIPLE_CHOICE': 4,
    'DROPDOWN': 5,
    'FULL_DATE': 6,
    'AGE': 7,
    'YES_OR_NO': 8,
    'SHORT_DATE': 9,
    'TERMS_AND_CONDITIONS': 10,
    'TEXT_BLOCK': 999,
    'IMAGE_BLOCK': 998
}


const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    backgroundColor: '#f0f0f0',
    padding: theme.spacing(2),
    paddingBottom: 270
}));

const Content = styled('div')(({ theme }) => ({
    width: theme.breakpoints.values.md,
    marginRight: 'auto',
    marginLeft: 'auto'
}));

const AttendeeFormContainer = styled(Card)(({ theme }) => ({
    marginTop: theme.spacing(4)
}));

const SubmitButton = styled(Button)(({ theme }) => ({
    marginLeft: theme.spacing(2)
}));
SubmitButton.defaultProps = { variant: 'contained' };

const CancelButton = styled(Button)(({ theme }) => ({
    borderColor: theme.palette.warning.dark,
    color: theme.palette.warning.dark
}));
CancelButton.defaultProps = { variant: 'outlined' };