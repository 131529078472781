import React, { useEffect, useRef } from 'react';

// Material-UI
import { styled } from '@material-ui/core/styles';
import { Grid, Box, TextField } from '@material-ui/core';

// Utility
import { Trans, t } from '@lingui/macro';

export default function CustomerForm(props) {
    const { onFormChange, onAddressAutocomplete } = props;
    const { firstName, lastName, email, emailConfirm, phone, address, city, province, country, postalCode } = props.values;

    function handleFormChange(e) {
        if (e.target.id === 'pleaseDoNotAutofillThis') {
            // Had to change the address field id to something else to reduce change broswers overlay their own autofill over the address autocomplete one
            onFormChange('address', e.target.value);
        } else {
            onFormChange(e.target.id, e.target.value);
        }
    }


    // Reset scroll position to top after component renders
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);


    const POSTAL = {
        'US': t`ZIP`,
        'CA': t`Postal Code`,
        'GB': t`Postcode`,
        'AU': t`Postcode`
    }


    // ***** Google Address Autofill Integration ***** //

    // The Address TextInput is used as the Google Maps address autocomplete widget. It doubles as the street address field.
    // Keep in mind, this is the only component that is not a controlled input. It stores its own state, and we copy any changes into the rest of our form state.

    const autoCompleteRef = useRef();
    const inputRef = useRef();

    useEffect(() => {
        (async () => {

            if (window.location.origin.includes('frontdoor.plus') === false) {
                // Google maps API key only works on frontdoor.plus domains, not localhost
                return;
            }

            if (window.googleMapsEnabled === false) {
                // Google maps APIs were not loaded
                return;
            }

            // Will return null if user does not give location permissions 
            //const bounds = await getBounds(0.5) // +/- 0.5 degrees = ~50km (very lazy math)

            // Initialize autocomplete widget
            autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, {
                componentRestrictions: { country: ["ca"] }, // Limiting results to Canada to keep autofill suggestions relevant. When we run events in the states, we'll need to add them back to the list.
                //bounds: bounds || undefined,
                fields: ["address_components"],
                types: ["address"]
            });
            // Add listener for when user selects an autocomplete value
            autoCompleteRef.current.addListener("place_changed", async function () {
                try {
                    const place = await autoCompleteRef.current.getPlace();
    
                    let streetNum = '';
                    let streetName = '';
                    let city = '';
                    let subLocality = '';
                    let province = '';
                    let country = '';
                    let postal = '';
                    
                    for (const component of place.address_components) {
                        const type = component.types[0];
    
                        switch (type) {
                            case 'street_number':
                                streetNum = component.long_name;
                                break;
                            case 'route':
                                streetName = component.long_name;
                                break;
                            case 'locality':
                                city = component.long_name;
                                break;
                            case 'sublocality_level_1':
                                subLocality = component.long_name;
                                break;
                            case 'administrative_area_level_1':
                                province = component.short_name;
                                break;
                            case 'country':
                                country = component.short_name;
                                break;
                            case 'postal_code':
                                postal = component.short_name.replace(' ', '');
                                break;
                            default:
                                break;
                        }
                    }
    
                    const address = `${streetNum} ${streetName}`;
    
                    inputRef.current.value = address
                    onAddressAutocomplete(address, subLocality || city, province, country, postal);
                }
                catch(error) {
                    return;
                }
            });
        })()
    // eslint-disable-next-line
    }, []);


    return (
        <Box display='flex' flexDirection='column' pb={2}>
            <h3><Trans>Contact Information</Trans></h3>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                    <SdTextField id='firstName' autoFocus label={t`First Name`} variant='outlined'
                        value={firstName.value} error={firstName.error} helperText={firstName.message} onChange={handleFormChange}/>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <SdTextField id='lastName' label={t`Last Name`} variant='outlined'
                        value={lastName.value} error={lastName.error} helperText={lastName.message} onChange={handleFormChange}/>
                </Grid>

                <Grid item xs={12} md={6}>
                    <SdTextField id='email' label={t`Email`} variant='outlined' type='email'
                        value={email.value} error={email.error} helperText={email.message} onChange={handleFormChange}/>
                </Grid>

                <Grid item xs={12} md={6}>
                    <SdTextField id='emailConfirm' label={t`Confirm Email`} variant='outlined' type='email'
                        value={emailConfirm.value} error={emailConfirm.error} helperText={emailConfirm.message} onChange={handleFormChange}/>
                </Grid>

                <Grid item xs={12} md={6}>
                    <SdTextField id='phone' label={t`Phone Number`} variant='outlined' type='tel'
                        value={phone.value} error={phone.error} helperText={phone.message} onChange={handleFormChange}/>
                </Grid>

                <Grid item xs={12}>
                    <SdTextField id='pleaseDoNotAutofillThis' label={t`Address`} variant='outlined' type='text' autoComplete='off' inputRef={inputRef}
                        defaultValue={address.value} error={address.error} helperText={address.message} onChange={handleFormChange}/>
                </Grid>

                <Grid item xs={(country.value === 'GB' || country.value === 'AU') ? 12 : 6}>
                    <SdTextField id='city' label={t`City`} variant='outlined'
                        value={city.value} error={city.error} helperText={city.message} onChange={handleFormChange}/>
                </Grid>

                {(country.value === 'US' || country.value === 'CA') && (
                    <Grid item xs={6}>
                        <ProvinceSelect region={country.value} province={province} onSelect={onFormChange} />
                    </Grid>
                )}

                <Grid item xs={6}>
                    <SdTextField id='postalCode' label={POSTAL[country.value]} variant='outlined' 
                        value={postalCode.value} error={postalCode.error} helperText={postalCode.message} onChange={handleFormChange}/>
                </Grid>
                
                <Grid item xs={6}>
                    <SdTextField id='country' select label={t`Country / Region`} variant='outlined' SelectProps={{native: true}} style={{width: '100%'}}
                        value={country.value} error={country.error} helperText={country.message} onChange={handleFormChange}>
                        <option value="CA">{t`Canada`}</option>
                        <option value="US">{t`United States`}</option>
                        <option value="GB">{t`United Kingdom`}</option>
                        <option value="AU">{t`Australia`}</option>
                    </SdTextField>
                </Grid>

            </Grid>
        </Box>
    )
}

const SdTextField = styled(TextField)({
    width: '100%'
});
SdTextField.defaultProps = { color: 'secondary' }

function ProvinceSelect(props) {
    const { region, province, onSelect } = props;

    switch (region) {

        case "CA":
            return (
                <SdTextField id='province' select label={t`Province`} variant='outlined' SelectProps={{native: true}} style={{width: '100%'}}
                    value={province.value} error={province.error} helperText={province.message} onChange={(e) => onSelect(e.target.id, e.target.value)}>
                        <option disabled value=""></option>
                        <option value="AB">Alberta</option>
                        <option value="BC">British Columbia</option>
                        <option value="MB">Manitoba</option>
                        <option value="NB">New Brunswick</option>
                        <option value="NL">Newfoundland and Labrador</option>
                        <option value="NS">Nova Scotia</option>
                        <option value="NU">Nunavut</option>
                        <option value="NT">Northwest Territories</option>
                        <option value="ON">Ontario</option>
                        <option value="PE">Prince Edward Island</option>
                        <option value="QC">Quebec</option>
                        <option value="SK">Saskatchewan</option>
                        <option value="YT">Yukon</option>
                </SdTextField>
            )            
        
        case "US":
            return (
                <SdTextField id='province' select label='State' variant='outlined' SelectProps={{native: true}} style={{width: '100%'}}
                    value={province.value} error={province.error} helperText={province.message} onChange={(e) => onSelect(e.target.id, e.target.value)}>
                    <option disabled value=""></option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                    <option value="PR">Puerto Rico</option>
                    <option value="VI">Virgin Islands</option>
                    <option value="MP">Northern Mariana Islands</option>
                    <option value="GU">Guam</option>
                    <option value="AS">American Samoa</option>
                    <option value="PW">Palau</option>
                </SdTextField>
            )
        default:
            break;
    }
}


// function getBounds(range) {
//     return new Promise((resolve, reject) => {
//         navigator.geolocation.getCurrentPosition(
//             location => {
//                 resolve({
//                     north: location.coords.latitude + range,
//                     south: location.coords.latitude - range,
//                     east: location.coords.longitude + range,
//                     west: location.coords.longitude - range,
//                 })
//                 resolve(location)
//             },
//             () => resolve(null)
//         )
//     })
// }