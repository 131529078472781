import React, { useEffect, useReducer } from 'react';
import { styled, Box, Typography, Button, Paper, TextField } from '@material-ui/core';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MainAppBar from 'components/MainAppBar';
import { lookupOrdersForCustomer, downloadTickets, createTicketLog } from 'utility/requests';
import * as isValid from 'utility/validation';
import { formatPrice } from 'utility/numbers';
import { downloadFile } from 'utility/browser';
import { useNotification } from 'utility/notification';
import dayjs from 'dayjs';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import * as GA4 from 'analytics/ga4';

const AppleWalletEN = 'https://frontdoorplus.s3.ca-central-1.amazonaws.com/storefront-images/web/apple-wallet-e.png';
const AppleWalletFR = 'https://frontdoorplus.s3.ca-central-1.amazonaws.com/storefront-images/web/apple-wallet-f.png';
const GoogleWalletEN = 'https://frontdoorplus.s3.ca-central-1.amazonaws.com/storefront-images/web/google-wallet-e.png';
const GoogleWalletFR = 'https://frontdoorplus.s3.ca-central-1.amazonaws.com/storefront-images/web/google-wallet-f.png';


// ========== Page State ========== ///

function reducer(state, action) {
    switch (action.type) {
        case 'emailChange':
            return { ...state, email: action.value.replace(/\s/g,''), message: '' };

        case 'last4Change':
            return { ...state, last4: action.value.replace(/\D/g,'').slice(0,4), message: '' };

        case 'submit':
            return { ...state, loading: true };

        case 'noResults':
            return { ...state, loading: false, message: t`No results found. Ensure your email address and last 4 digits are spelled correctly.` };

        case 'setOrders':
            return { ...state, orders: action.orders, loading: false, message: null };

        case 'formError':
            return { ...state, loading: false, message: t`There was a problem searching for your tickets.` };

        case 'startDownload':
            return { ...state, downloading: true };

        case 'finishDownload':
            return { ...state, downloading: false };

        case 'return':
            return { ...initialState, email: state.email, last4: state.last4 };

        default:
            throw new Error();
    }
}

const initialState = {
    email: '',
    last4: '',
    orders: null,
    message: null,
    loading: false,
    downloading: false
}



// ========== Page Component ========== ///

export default function TicketLookupPage() {

    const [state, dispatch] = useReducer(reducer, initialState);

    const { createNotification } = useNotification();
    const { i18n } = useLingui();


    const handleFormSubmit = () => {
        if (state.loading) return;
        dispatch({ type: 'submit' });

        lookupOrdersForCustomer(state.email, state.last4)
            .then(orders => {
                if (orders.length === 0) {
                    dispatch({ type: 'noResults' });
                } else {
                    dispatch({ type: 'setOrders', orders });
                }
            })
            .catch(e => {
                dispatch({ type: 'formError' });
            })
    };


    const handleTicketDownload = async (tranId) => {
        if (state.downloading) return;
        dispatch({ type: 'startDownload' });

        try {
            const tickets = await downloadTickets(tranId, state.email, state.last4, i18n.locale);
            await downloadFile('tickets.pdf', tickets);
            dispatch({ type: 'finishDownload' });
            createTicketLog(tranId, 202, state.email).catch(() => null)
        }
        catch(e) {
            createNotification(t`Unable to download tickets`, { variant: 'error' });
            dispatch({ type: 'finishDownload' });
        }
    };


    if (state.orders === null) {
        return (
            <>
                <MainAppBar />

                <LookupForm
                    { ...state }
                    onEmailChange={(value) => dispatch({ type: 'emailChange', value: value })}
                    onLast4Change={(value) => dispatch({ type: 'last4Change', value: value })}
                    onSubmit={handleFormSubmit}
                />
            </>
        )
    }

    else {
        return (
            <>
                <MainAppBar />

                <OrderList
                    { ...state }
                    locale={i18n.locale}
                    onDownload={handleTicketDownload}
                    onReturn={() => dispatch({ type: 'return' })}
                />
            </>
        )
    }
}



// ========== React Components ========== ///

function LookupForm(props) {

    const emailValid = isValid.email(props.email);
    const last4Valid = Boolean(props.last4.match(/^\d{4}$/));
    const disabled = emailValid === false || last4Valid === false;

    useEffect(() => {
        // Reset scroll position to top after component renders
        window.scrollTo(0,0);
        // Log page view
        GA4.pageView('Ticket Lookup');
    }, []);

    // Submit if enter key is hit
    function onKeyPress(e) {
        if (e.key === 'Enter' && emailValid && last4Valid) {
            props.onSubmit();
        }
    }

    return (
        <Root>
            <Form>

                <Typography variant='h5'><Trans>Ticket Lookup</Trans></Typography>

                <Box mb={6} mt={2}>
                    <Typography><Trans>Lost your tickets? You can find your tickets using the form below. You will need the following information:</Trans></Typography>

                    <ul>
                        <li><Trans>Email address used to buy your tickets</Trans></li>
                        <li><Trans>Last 4 digits of the credit card used to buy your tickets</Trans></li>
                    </ul>

                    <Typography><Trans>If you did not use a credit card to order your tickets, contact us at hello@frontdoorplus.com</Trans></Typography>
                </Box>

                <Input
                    label={t`Email Address`}
                    variant='outlined'
                    color='secondary'
                    fullWidth
                    type='email'
                    onKeyUp={onKeyPress}
                    value={props.email}
                    onChange={e => props.onEmailChange(e.target.value)}
                />
                <Input
                    label={t`Last 4 Digits`}
                    variant='outlined'
                    color='secondary'
                    fullWidth
                    onKeyUp={onKeyPress}
                    value={props.last4}
                    onChange={e => props.onLast4Change(e.target.value)}
                />

                <Typography color='error'>{props.message ? props.message : ''}</Typography>

                <FormFooter>
                    <SubmitButton variant='contained' color='primary' onClick={props.onSubmit} disabled={disabled}><Trans>Submit</Trans></SubmitButton>
                </FormFooter>
            </Form>
        </Root>
    )
}

function OrderList(props) {

    useEffect(() => {
        // Reset scroll position to top after component renders
        window.scrollTo(0,0);
        // Log page view
        GA4.pageView('Ticket Lookup Results');
    }, []);

    return (
        <Root>

            <BackButton onClick={props.onReturn} variant='outlined' startIcon={<ArrowBackIcon />}><Trans>Back</Trans></BackButton>

            <Header variant='h4'><Trans>Your Orders</Trans></Header>

            <Box mt={4} mb={8}>
                <Description><Trans>Here are the orders placed with your email and credit card. Find the correct order, then add your tickets to your wallet. If you want to print your tickets, click the download button to get a PDF document. You will need to present your ticket(s) at the entrance of the event.</Trans></Description>
                <Typography><Trans>To add tickets to your Apple Wallet, open this webpage using Safari on your iPhone, iPad or Mac, then click "Add to Apple Wallet".</Trans></Typography>
            </Box>


            {props.orders.map(o => (
                <ListItem key={o.tran_id}>

                    <ListHeader>
                        <Typography variant='h6'>{o.event_title}</Typography>
                    </ListHeader>

                    <ListContent>

                        <Table size='small'>
                            <TableBody>
                                <TableRow>
                                    <TableCell><b><Trans>Total</Trans></b></TableCell>
                                    <TableCell align='right'><b>{formatPrice(o.total)}</b></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><b><Trans>Ordered By</Trans></b></TableCell>
                                    <TableCell align='right'><b>{`${o.first_name} ${o.last_name}`}</b></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><b><Trans>Order Date</Trans></b></TableCell>
                                    <TableCell align='right'><b>{dayjs(o.created_at).format('MMMM D, h:mm a')}</b></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                    </ListContent>

                    <ListFooter>
                        <WalletContainer>
                            <WalletButton href={o.googleWalletURL + '&lang=' + props.locale} target="_blank" rel="noopener noreferrer">
                                <WalletImage src={props.locale === 'fr' ? GoogleWalletFR : GoogleWalletEN} alt={t`Add to Google Wallet`} />
                            </WalletButton>
                            {o.ticket_count <= 10 && (
                                <WalletButton href={o.appleWalletURL + '&lang=' + props.locale} target="_blank" rel="noopener noreferrer">
                                    <WalletImage src={props.locale === 'fr' ? AppleWalletFR : AppleWalletEN} alt={t`Add to Apple Wallet`} />
                                </WalletButton>
                            )}
                        </WalletContainer>
                        <DownloadButton variant='outlined' onClick={() => props.onDownload(o.tran_id)} disabled={props.downloading}><Trans>Download</Trans></DownloadButton>
                    </ListFooter>
                    
                </ListItem>
            ))}

            <Description><Trans>Don't see your order? Contact us using one of the links below.</Trans></Description>

        </Root>
    )
}



// ========== UI Components ========== //

const Root = styled('div')(({ theme }) => ({
    maxWidth: theme.breakpoints.values.sm,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
}));

const Form = styled(Paper)(({ theme }) => ({
    maxWidth: theme.breakpoints.values.sm,
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
}));

const Input = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(2)
}));

const FormFooter = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end'
}));

const SubmitButton = styled(Button)(({ theme }) => ({
    marginLeft: 'auto'
}));

const DownloadButton = styled(Button)(({ theme }) => ({
    height: theme.spacing(5),
    width: theme.spacing(14),
    color: theme.palette.info.dark,
    borderColor: theme.palette.info.dark
}));

const BackButton = styled(Button)(({ theme }) => ({
    color: theme.palette.info.dark,
    borderColor: theme.palette.info.dark
}));

const WalletContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        justifyContent: 'space-around'
    },
}));

const WalletButton = styled('a')(({ theme }) => ({
    height: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(3)
    },
}));

const WalletImage = styled('img')(({ theme }) => ({
    height: theme.spacing(5)
}));

const Header = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(4)
}));

const Description = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(3)
}));

const ListItem = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: '12px',
    borderRadius: theme.spacing(2)
}));

const ListHeader = styled('div')(({ theme }) => ({
    padding: theme.spacing(2)
}));

const ListContent = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    maxWidth: theme.breakpoints.values.sm
}));

const ListFooter = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing(2),
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center'
    },
}));