import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';



export default function PageNotFound(props) {
    return (
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' mt={12}>
            <Typography variant='h4'>Page not Found</Typography>
            <Box height={32} />
            <Link to='/'>
                <Button variant='outlined'>Go to Home</Button>
            </Link>
        </Box>
    )
}
