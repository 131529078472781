// Note: meta pixel script must be loaded in the <head> of index.html first

// This array keeps track of the pixels that have already been initialized.
// We should never clear the values here or else a pixel could be initialized multiple times
const pixels = [];


/**
 * Initialize a Meta Pixel. A pixel must be initialized before it can send events to Meta
 * @param {string} id
 */
export function initialize(id) {
    try {
        if (!id) {
            return console.warn('Cannot initialize meta pixel. No valid ID provided.');
        };
        if (pixels.includes(id)) {
            return console.warn(`Meta pixel ${id} has already been initialized.`)
        };

        window.fbq('set', 'autoConfig', false, id); // Disable automatic events

        window.fbq('init', id);
        pixels.push(id);
    }
    catch(e) { console.error(e); }
}


/**
 * Log a ViewContent event for the provided Meta Pixel only
 * @param {string} id   Meta Pixel ID
 * @param {string} name Name of the content viewed
 */
export function viewContent(id, name) {
    try {
        if (!id) {
            return console.warn('Cannot initialize meta pixel. No valid ID provided.');
        };

        window.fbq('trackSingle', id, 'ViewContent', { content_name: name })
    }
    catch(e) { console.error(e); }
}


/**
 * Log an InitiateCheckout event for the provided Meta Pixel only
 * @param {string} id Meta Pixel ID
 */
export function initiateCheckout(id) {
    try {
        if (!id) {
            return console.warn('Cannot initialize meta pixel. No valid ID provided.');
        };

        window.fbq('trackSingle', id, 'InitiateCheckout')
    }
    catch(e) { console.error(e); }
}


/**
 * Log a Purchase event for the provided Meta Pixel only
 * @param {string} id       Meta Pixel ID
 * @param {number} value    Dollar value of the purchase
 */
export function purchase(id, value) {
    try {
        if (!id) {
            return console.warn('Cannot initialize meta pixel. No valid ID provided.');
        };

        window.fbq('trackSingle', id, 'Purchase', { currency: 'CAD', value })
    }
    catch(e) { console.error(e); }
}