import React, { useState, useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";
import { Box, Typography, Button, CircularProgress, Card, IconButton } from '@material-ui/core';
import { getEventsByHostId, getHostById } from 'utility/requests';
import { Trans, t } from '@lingui/macro';
import * as GA4 from 'analytics/ga4';
import { Link } from 'react-router-dom';
import { setPageMetadata } from 'pageMetadata';

// Images + Icons
import LanguageIcon from '@material-ui/icons/Language';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';

import MainAppBar from 'components/MainAppBar';
import EventListItem from 'components/EventListItem';



export default function HostPage() {
    const [state, setState] = useState({
        events: null,
        host: null,
        loading: true,
        error: false,
        success: false
    });

    const theme = useTheme();
    const params = useParams();


    // Reset scroll position to top after component renders
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);


    const hostId = params.hostId.replace(/[^0-9]/g,'');


    let socialLinksAvailable = false;

    if (state.success) {
        const { url_website, url_facebook, url_twitter, url_instagram, url_tiktok } = state.host;
        socialLinksAvailable = Boolean((url_website || url_facebook || url_twitter || url_instagram || url_tiktok));
    }

    
    useEffect(() => {
        Promise.all([getEventsByHostId(hostId), getHostById(hostId)])
            .then(([events, host]) => {
                setState({
                    events: events,
                    host: host,
                    loading: false,
                    error: false,
                    success: true
                });

                // Hardcode the path since users still get here from the old URL scheme where host id is passed in via query parameter on the root url. (Ex: https://frontdoor.plus/?o=123)
                GA4.pageView(`Host: ${host.host_name}`, `/host/${hostId}`);

                // Set HTML page title and description
                setPageMetadata({
                    title: `${host.host_name} Tickets`,
                    description: `Check out these upcoming events by ${host.host_name}`,
                    image: null
                })
            })
            .catch(e => {
                setState({
                    events: null,
                    host: null,
                    loading: false,
                    error: true,
                    success: false
                })
            })
    }, [hostId]);


    return (
        <>

            <MainAppBar />

            {state.loading && (
                <CircularProgress />
            )}

            {state.error && (
                <Box display='flex' flexDirection='column' height='100%' width='100%' alignItems='center' justifyContent='center'>
                    <Typography style={{marginBottom: 24}} variant='h5'><Trans>Sorry we couldn't find that organizer</Trans></Typography>
                    <Link style={{textDecoration: 'none'}} to='/'>
                        <Button variant='outlined'><Trans>View all events</Trans></Button>
                    </Link>
                </Box>
            )}

            {state.success && (
                <Box p={[2,4]} pb={[8]} mx={[0,0,0,'auto']} maxWidth={theme.breakpoints.values.lg}>

                    <EventCard>
                        <Title variant='h4'>{state.host.host_name}</Title>

                        <Box display='flex' flexDirection='column' alignItems={'center'} mt='24px'>

                            {state.host.host_desc ? (
                                <HostDescription variant='h6'>{state.host.host_desc}</HostDescription>
                            ) : null}


                            {socialLinksAvailable && (
                                <Box display='flex' justifyContent={'center'} alignItems='center'>
                                    {state.host.url_website && (
                                        <IconButton aria-label={t`Website`} href={state.host.url_website} target="_blank" rel="noopener">
                                            <Website />
                                        </IconButton>
                                    )}
                                    {state.host.url_facebook && (
                                        <IconButton aria-label="Facebook" href={state.host.url_facebook} target="_blank" rel="noopener">
                                            <Facebook />
                                        </IconButton>
                                    )}
                                    {state.host.url_twitter && (
                                        <IconButton aria-label="Twitter" href={state.host.url_twitter} target="_blank" rel="noopener">
                                            <Twitter />
                                        </IconButton>
                                    )}
                                    {state.host.url_instagram && (
                                        <IconButton aria-label="Instagram" href={state.host.url_instagram} target="_blank" rel="noopener">
                                            <Instagram />
                                        </IconButton>
                                    )}
                                    {state.host.url_tiktok && (
                                        <IconButton aria-label="Tiktok" href={state.host.url_tiktok} target="_blank" rel="noopener">
                                            <Tiktok alt='' src={process.env.REACT_APP_STOREFRONT_S3_LOCATION + '/web/tiktok-icon-black.svg'} />
                                        </IconButton>
                                    )}
                                </Box>
                            )}

                        </Box>

                    </EventCard>

                </Box>
            )}

            {state.success && (
                <Box p={[2,4]} pb={[8]} mx={[0,0,0,'auto']} maxWidth={theme.breakpoints.values.lg}>

                    {state.events.length === 0 && (
                        <Typography align='center'><Trans>There are no scheduled events for sale at the moment</Trans></Typography>
                    )}

                    {state.events.length > 0 && (
                        <Title variant='h5'><Trans>Upcoming Events</Trans></Title>
                    )}

                    {state.events.map(e => (
                        <EventListItem key={e.event_id} event={e} />
                    ))}

                </Box>
            )}

        
        </>
    );
}


const EventCard = styled(Card)(({ theme }) => ({
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: theme.spacing(2)    
}));

const Website = styled(LanguageIcon)({
    color: '#000'
})

const Facebook = styled(FacebookIcon)({
    color: '#3b5998'
})

const Twitter = styled(TwitterIcon)({
    color: '#55acee'
})

const Instagram = styled(InstagramIcon)({
    color: '#E4405F'
})

const Tiktok = styled('img')(({ theme }) => ({
    width: 20,
    height: 20
}));

const Title = styled(Typography)({
    marginBottom: '16px',
    fontWeight: 'bold'
});

const HostDescription = styled(Typography)({
    flex: 1,
    marginBottom: '32px',
    textAlign: 'center'
});