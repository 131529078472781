import React from 'react';

// Material-UI
import { Paper, Box, IconButton, Typography, styled, Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import LockOpenIcon from '@material-ui/icons/LockOpen';

// Utility
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';


export default function Product(props) {
    const { onAdd, onRemove, chargesEnabled } = props;
    const { id, name, description, price, qty, available, minPurchase, maxPurchase, hasCode, bundleProductsAvailable, originalValue } = props.product;

    const { i18n } = useLingui();

    const soldOut = available <= 0 || minPurchase > available || bundleProductsAvailable === 0; // bundleProductsAvailable will be undefined if not a bundle
    const unavailable = chargesEnabled === 0;
    
    let quantityText = qty;

    let savingsPercent = originalValue ? Math.round((1 - (price / originalValue)) * 100) : null;

    if (soldOut) quantityText = t`Sold out`;
    if (unavailable) quantityText = t`Unavailable`;


    const disableAddButton = soldOut || unavailable || qty >= available || qty >= maxPurchase;
    const disableRemoveButton = soldOut || unavailable || qty <= 0;


    const Container = hasCode ? UnlockedProduct : RegularProduct;


    return (
        <Container>
            
            <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
                <Title>{name}</Title>
                {hasCode ? <UnlockedIcon /> : null}
            </Box>

            <Divider />

            <Body>
                <Box flexGrow={1}>

                    <Box>
                        <Price>
                            {price > 0
                                ? i18n.number(price, { style: 'currency', currency: 'CAD', currencyDisplay: 'narrowSymbol' })
                                : t`FREE`
                            }
                        </Price>
                        {(savingsPercent !== null && savingsPercent > 0) && (
                            <Savings>({t`Save`} {savingsPercent}%)</Savings>
                        )}
                    </Box>

                    <Box mb={2} />

                    <ProductDescription>{description}</ProductDescription>

                </Box>

                <CartControl>
                    
                    {(soldOut || unavailable) && (
                        <Box display='flex' alignItems='center' px={4} py='0.5rem' bgcolor='#f0f0f0' borderRadius='4px'>{quantityText}</Box>
                    )}

                    {(!soldOut && !unavailable) && (
                        <>
                            {disableRemoveButton ? (
                                <RemoveButtonDisabled>
                                    <RemoveIcon />
                                </RemoveButtonDisabled>
                            ) : (
                                <RemoveButton onClick={() => onRemove(id)}>
                                    <RemoveIcon />
                                </RemoveButton>
                            )}
            
                            <Box display='flex' alignItems='center' px={4} py='0.5rem' bgcolor='#f0f0f0' borderRadius='4px'>{quantityText}</Box>
                            
                            {disableAddButton ? (
                                <AddButtonDisabled>
                                    <AddIcon />
                                </AddButtonDisabled>
                            ) : (
                                <AddButton onClick={() => onAdd(id)}>
                                    <AddIcon />
                                </AddButton>
                            )}
                        </>
                    )}

                </CartControl>
            </Body>


        </Container>
    )
}


const RegularProduct = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(3),

    // Desktop Styles
    [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between',
        alignItems: 'center'
    },

    // Mobile Styles
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    }
}));

const UnlockedProduct = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderColor: theme.palette.success.main,
    border: '2px solid',

    // Desktop Styles
    [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between',
        alignItems: 'center'
    },

    // Mobile Styles
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    }
}));

const Title = styled(Typography)(({ theme }) => ({
    fontSize: '1.5rem',
}));
Title.defaultProps = { variant: 'h3' };

const UnlockedIcon = styled(LockOpenIcon)(({ theme }) => ({
    color: theme.palette.success.main,
    marginLeft: theme.spacing(1)
}));

const Body = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginTop: theme.spacing(1),

    // Desktop Styles
    [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    // Mobile Styles
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
    }
}));

const Price = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: '1.2rem'
}));
Price.defaultProps = { display: 'inline' };

const Savings = styled(Typography)(({ theme }) => ({
    color: theme.palette.success.main,
    marginLeft: theme.spacing(1),
    fontSize: '1.2rem'
}));
Savings.defaultProps = { display: 'inline' };

const ProductDescription = styled(Typography)(({ theme }) => ({
    whiteSpace: 'pre-wrap'
}));
ProductDescription.defaultProps = { variant: 'body1' };

const CartControl = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: 180,
    height: 40,
    justifyContent: 'center',

    // Mobile Styles
    [theme.breakpoints.down('sm')]: {
        alignSelf: 'flex-end',
        marginTop: theme.spacing(4)
    }
}));


const AddButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.success.dark,
    color: 'white',
    padding: '0.5rem',
    marginLeft: theme.spacing(1),
    '&:hover': { // Keep the same bg color when user hovers over btn
        backgroundColor: theme.palette.success.dark
    }
}));

const AddButtonDisabled = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.success.light,
    color: 'white',
    padding: '0.5rem',
    marginLeft: theme.spacing(1),
    '&:hover': { // Keep the same bg color when user hovers over btn
        backgroundColor: theme.palette.success.light,
        cursor: 'default'
    }
}));
AddButtonDisabled.defaultProps = { disableRipple: true };

const RemoveButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.error.dark,
    color: 'white',
    padding: '0.5rem',
    marginRight: theme.spacing(1),
    '&:hover': { // Keep the same bg color when user hovers over btn
        backgroundColor: theme.palette.error.dark
    }
}));

const RemoveButtonDisabled = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.error.light,
    color: 'white',
    padding: '0.5rem',
    marginRight: theme.spacing(1),
    '&:hover': { // Keep the same bg color when user hovers over btn
        backgroundColor: theme.palette.error.light,
        cursor: 'default'
    }
}));
RemoveButtonDisabled.defaultProps = { disableRipple: true };

// Old add/remove button colors
//  add: #60d379
//  remove: #e75454