import React, { useState, useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import { getAllUpcomingEvents } from 'utility/requests';
import { Trans } from '@lingui/macro';
import * as GA4 from 'analytics/ga4';


import MainAppBar from 'components/MainAppBar';
import EventListItem from 'components/EventListItem';



export default function LandingPage() {
    const [events, setEvents] = useState({ values: null, loading: true, error: false, success: false });

    const theme = useTheme();

    
    useEffect(() => {
        GA4.pageView('Landing Page');
    }, []);
    
    useEffect(() => {
        getAllUpcomingEvents()
            .then(events => setEvents({
                values: events,
                loading: false,
                error: false,
                success: true
            }))
            .catch(e => {
                setEvents({
                    values: null,
                    loading: false,
                    error: true,
                    success: false
                })
            })
    }, []);


    return (
        <>
            <MainAppBar />


            <Box p={[2,4]} pb={[8]} mx={[0,0,0,'auto']} maxWidth={theme.breakpoints.values.lg}>

                <PageHeader><Trans>Upcoming Events: Discover Your Next Exciting Experience</Trans></PageHeader>

                {events.loading && (
                    <Box display='flex' flexDirection='column' height='100%' width='100%' alignItems='center' justifyContent='center'>
                        <CircularProgress />
                    </Box>
                )}

                {events.error && (
                    <Box display='flex' flexDirection='column' height='100%' width='100%' alignItems='center' justifyContent='center'>
                        <Typography style={{marginBottom: 24}} variant='h5'><Trans>Sorry we couldn't find any events</Trans></Typography>
                    </Box>
                )}

                {events.success && events.values.length === 0 && (
                    <Box display='flex' flexDirection='column' height='100%' width='100%' alignItems='center' justifyContent='center'>
                        <Typography align='center'><Trans>There are no scheduled events for sale at the moment</Trans></Typography>
                    </Box>
                )}

                {events.success && events.values.map(e => (
                    <EventListItem key={e.event_id} event={e} includeHostLink />
                ))}

            </Box>

        
        </>
    );
}

const PageHeader = styled(Typography)(({ theme }) => ({
    fontSize: '2.4rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(10),
}));
PageHeader.defaultProps = { variant: 'h1' };

const Title = styled(Typography)({
    fontSize: '1.6rem',
    fontWeight: 'bold',
});
Title.defaultProps = { variant: 'h2' };