import React from 'react';

// Material-UI
import { useTheme, styled } from '@material-ui/core/styles';
import { Box, Card, CardContent, IconButton, Typography, Chip, Paper, Divider } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import ScheduleIcon from '@material-ui/icons/Schedule';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { Trans, t } from '@lingui/macro';
import { Link } from 'react-router-dom';


export default function EventHeader(props) {
    const { title, hostName, hostId, date, concluded, address, description, imageUrl, websiteUrl, facebookUrl, twitterUrl, instagramUrl, tiktokUrl } = props;
    const theme = useTheme();

    const socialLinksAvailable = Boolean((websiteUrl || facebookUrl || twitterUrl || instagramUrl || tiktokUrl));

    return (
        <Box display='flex' flexDirection='column' alignItems='flex-start' px={[2,4]} pt={[2,4]} mb={8} mx={[0,0,0,'auto']} maxWidth={theme.breakpoints.values.lg}>

            {concluded === 1 && (
                <ConcludedChip label={t`Event Concluded`} />
            )}

            <HeaderCard>

                <ImageContainer>
                    <Image src={imageUrl} alt='' />
                </ImageContainer>

                <HeaderCardBody>

                    <EventInfo>
                        <Title>{title}</Title>
                        <Subtitle to={`/host/${hostId}`}>{hostName}</Subtitle>

                        <Box display='flex' alignItems='center' mt={3} mb={1}>
                            <ScheduleIcon />
                            <IconLabel variant='body1'>{date}</IconLabel>
                        </Box>

                        <Box display='flex' alignItems='center'>
                            <LocationOnIcon />
                            <IconLabel variant='body1'>{address}</IconLabel>
                        </Box>
                    </EventInfo>

                    {socialLinksAvailable && (
                        <Box display='flex' justifyContent='flex-end'>

                            {websiteUrl && (
                                <IconButton aria-label={t`Website`} href={websiteUrl} target="_blank" rel="noopener">
                                    <Website />
                                </IconButton>
                            )}
                            {facebookUrl && (
                                <IconButton aria-label="Facebook" href={facebookUrl} target="_blank" rel="noopener">
                                    <Facebook />
                                </IconButton>
                            )}
                            {twitterUrl && (
                                <IconButton aria-label="Twitter" href={twitterUrl} target="_blank" rel="noopener">
                                    <Twitter />
                                </IconButton>
                            )}
                            {instagramUrl && (
                                <IconButton aria-label="Instagram" href={instagramUrl} target="_blank" rel="noopener">
                                    <Instagram />
                                </IconButton>
                            )}
                            {tiktokUrl && (
                                <IconButton aria-label="Tiktok" href={tiktokUrl} target="_blank" rel="noopener">
                                    <Tiktok alt='' src={process.env.REACT_APP_STOREFRONT_S3_LOCATION + '/web/tiktok-icon-black.svg'} />
                                </IconButton>
                            )}

                        </Box>
                    )}

                </HeaderCardBody>

            </HeaderCard>

            <Header>
                <Trans>About</Trans>
            </Header>

            <HeaderDivider />

            <DescriptionCard>
                <CardContent>
                    <Description variant='body1'>{description}</Description>
                </CardContent>
            </DescriptionCard>
            

        </Box>
    )
}

const ConcludedChip = styled(Chip)(({ theme }) => ({
    color: theme.palette.getContrastText(theme.palette.warning.dark),
    backgroundColor: theme.palette.warning.dark,
    marginBottom: '24px'
}));

const Title = styled(Typography)(({ theme }) => ({
    fontSize: '1.6rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
        fontSize: 22
    }
}));
Title.defaultProps = { variant: 'h1' };


const HeaderCard = styled(Paper)(({ theme }) => ({
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(8),
    width: '100%',
    marginTop: theme.spacing(2),

    // Desktop + Tablet Style
    [theme.breakpoints.up('lg')]: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    // Mobile Style
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        marginBottom: theme.spacing(8),
    }
}));

const ImageContainer = styled('div')(({ theme }) => ({
    // Desktop + Tablet Style
    [theme.breakpoints.up('lg')]: {
        height: '260px',
        width: '520px',
        borderTopLeftRadius: theme.spacing(2),
        borderBottomLeftRadius: theme.spacing(2),
    },
    // Mobile Style
    [theme.breakpoints.down('md')]: {
        width: '100%',
        borderTopLeftRadius: theme.spacing(2),
        borderTopRightRadius: theme.spacing(2),
    }
}));

const HeaderCardBody = styled(Box)(({ theme }) => ({
    // Desktop Style
    [theme.breakpoints.up('lg')]: {
        flexGrow: 1,
        minHeight: '260px',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    // Mobile + Tablet Style
    [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    }
}));

const EventInfo = styled(Box)(({ theme }) => ({
    // Desktop Style
    [theme.breakpoints.up('lg')]: {
        flexGrow: 1,
        paddingTop: theme.spacing(2)
    },
    // Mobile + Tablet Style
    [theme.breakpoints.down('md')]: {
        flexGrow: 1,
        padding: theme.spacing(2),
    }
}));

const Image = styled('img')(({ theme }) => ({
    width: '100%',
    objectFit: 'contain',
    [theme.breakpoints.up('lg')]: {
        borderTopLeftRadius: theme.spacing(2),
        borderBottomLeftRadius: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
        borderTopLeftRadius: theme.spacing(2),
        borderTopRightRadius: theme.spacing(2),
    }
}));

const IconLabel = styled(Typography)(({ theme }) => ({
    marginLeft: '16px'
}));

const Subtitle = styled(Link)(({ theme }) => ({
    ...theme.typography.caption,
    fontSize: '1rem',
    color: '#616161'
}));

const Website = styled(LanguageIcon)({
    color: '#000'
})

const Facebook = styled(FacebookIcon)({
    color: '#3b5998'
})

const Twitter = styled(TwitterIcon)({
    color: '#55acee'
})

const Instagram = styled(InstagramIcon)({
    color: '#E4405F'
})

const Tiktok = styled('img')(({ theme }) => ({
    width: 20,
    height: 20
}));

const DescriptionCard = styled(Card)(({ theme }) => ({
    marginTop: 32,
    width: '100%',
    borderRadius: theme.spacing(2)
}));

const Description = styled(Typography)(({ theme }) => ({
    whiteSpace: 'pre-wrap'
}));

const Header = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: '2.1rem'
}));
Header.defaultProps = { variant: 'h2' };

const HeaderDivider = styled(Divider)(({ theme }) => ({
    marginTop: theme.spacing(1),
    height: '2px',
    backgroundColor: theme.palette.common.white,
    width: '100%'
}));