export async function downloadFile(name, file) {

    const isBinary = typeof file === 'object';

    // Create URL to the file
    let url;

    if (isBinary) {
        // File is in binary format. Create an object URL to access it.
        url = window.URL.createObjectURL(file);
    } else {
        const res = await fetch(file);
        const blob = await res.blob();
        
        // File is a dataURI. We can access it directly.
        url = window.URL.createObjectURL(blob);
    }

    // Create anchor tag for file and click it
    let a = document.createElement('a');
    a.href = url;
    a.download = name;
    a.click();

    // Release the object URL. Its no longer needed
    window.URL.revokeObjectURL(url);
}