export function calculateOrder(cart, event, discount, paidTransaction) {

    // Number of paid products in cart
    const paidProductCount = cart.reduce((total, num) => {
        if (num.price > 0) {
            return total + (num.qty * num.paidProductCount)
        } else {
            return total
        }
    }, 0)

    const ourTaxRateMultiplier = 1.13;


    // ************** Sub Total and Discounts ************** //
    const subTotal = Number(
        cart.reduce((total, num) => (total + (num.qty * num.price)), 0).toFixed(2)
    );
    
    let discountTotal = Number(
        ((subTotal * discount.percent) + discount.amount).toFixed(2)
    );
    if (discountTotal > subTotal) discountTotal = subTotal;
    
    // We have to subtract any discounts before calculating any fees on the subtotal
    const subTotalCalc = Number(
        (subTotal - discountTotal).toFixed(2)
    );

    // ************** Our Fees ************** //

    let serviceFee = 0, serviceFeeCalc = 0;

    if (paidTransaction) {
        const { feePerItem, feePerOrder, percentFeePerOrder, feeMinAmount, feeMaxAmount, taxableB4tFees } = event.fees;

        const ourOrderPercent = cart.reduce((total, p) => {
            let feePerTicket = p.price * percentFeePerOrder;

            if (feePerTicket > 0) {
                if (feeMinAmount && feePerTicket < feeMinAmount) {
                    feePerTicket = feeMinAmount;
                }
    
                if (feeMaxAmount && feePerTicket > feeMaxAmount) {
                    feePerTicket = feeMaxAmount;
                }
            }

            const result = (p.qty * feePerTicket)

            return total + result;
        }, 0);

        serviceFee = Number(
            (((paidProductCount * feePerItem) + feePerOrder + ourOrderPercent) * (taxableB4tFees ? ourTaxRateMultiplier : 1)).toFixed(2)
        );
        serviceFeeCalc = event.hostAbsorbsB4tFees ? 0 : serviceFee;
    }


    // ************** Taxes ************** //
    
    let tax = 0;
    
    if (paidTransaction) {
        const { taxable, taxIncluded } = event;
        const { taxRate } = event.fees;

        tax = (taxable && !taxIncluded)
            ? Number((subTotalCalc * taxRate).toFixed(2))
            : 0;
    }


    // ************** CC Fees ************** //

    let creditCardFee = 0, creditCardFeeCalc = 0;    
    
    if (paidTransaction) {
        const { ccFlatFee, ccPercent, taxableCcFees } = event.fees;

        if (event.hostAbsorbsCcFees) {
            // ** CC Fee is included in the ticket price ** //
            let _ccPercentFee = (subTotalCalc + tax + serviceFeeCalc) * ccPercent
            let _ccFlatFee = ccFlatFee;

            creditCardFee = Number(
                ((_ccPercentFee + _ccFlatFee) * (taxableCcFees ? ourTaxRateMultiplier : 1)).toFixed(2)
            );
            creditCardFeeCalc = 0;
        } else {
            // ** CC Fee is passed on to the customer ** //
            // Calc CC fee considering that increasing the final amount also increases the Stripe fee
            const originalCharge = subTotalCalc + tax + serviceFeeCalc;
            const adjustedCharge = (originalCharge + ccFlatFee) / (1 - ccPercent);
        
            let _ccPercentFee = adjustedCharge * ccPercent;
            let _ccFlatFee = ccFlatFee;

            creditCardFee = Number(
                ((_ccPercentFee + _ccFlatFee) * (taxableCcFees ? ourTaxRateMultiplier : 1)).toFixed(2)
            );
            creditCardFeeCalc = creditCardFee;
        }
    }


    // ************** Totals ************** //

    const total = Number((subTotalCalc + serviceFeeCalc + tax + creditCardFeeCalc).toFixed(2));
    const unifiedFee = Number((serviceFeeCalc + creditCardFeeCalc).toFixed(2));


    return {
        subTotal,
        discountTotal,
        paidTransaction,
        serviceFee,
        creditCardFee,
        tax,
        total,
        unifiedFee
    }
}