import React from 'react';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { styled, Box, Button, CircularProgress, TextField, Paper, Typography } from '@material-ui/core';

// Components
import OrderReceipt from 'components/OrderReceipt';

// Utility
import { Trans, t } from '@lingui/macro';


export default function OrderSummary(props) {
    const { products, bundles, event, paidTransaction, discount, discountFormState, showDiscountForm, onClick, onDiscountSubmit, onDiscountChange, onDiscountDelete, disablePurchase, errorMessage } = props;

    const c = useStyles();

    const discountErrorMessages = {
        error: t`Unable to verify code`,
        invalid: t`Not a valid code`,
        expired: t`This code is expired`
    }

    return (
        <div>
            <h3><Trans>Order Summary</Trans></h3>

            {showDiscountForm && discountFormState.status === 'success' && (
                <DiscountForm>
                    <TextField
                        size='small'
                        variant='outlined'
                        color='secondary'
                        value={discountFormState.code}
                        label={t`Discount Code`}
                        helperText={t`Discount applied`}
                        InputProps={{classes:{root: c.inputRoot}, readOnly: true}}
                        InputLabelProps={{classes:{root: c.inputLabelRoot}}}
                        FormHelperTextProps={{classes:{root: c.formHelperTextRoot}}}
                    />
                    <DiscRemoveButton size='small' variant='outlined' onClick={onDiscountDelete}><Trans>Remove</Trans></DiscRemoveButton>
                </DiscountForm>
            )}
            
            {showDiscountForm && discountFormState.status !== 'success' && (
                <DiscountForm>
                    <TextField
                        size='small'
                        variant='outlined'
                        color='secondary'
                        fullWidth
                        value={discountFormState.code}
                        disabled={discountFormState.status === 'loading'}
                        label={t`Discount Code`}
                        error={['invalid', 'error', 'expired'].includes(discountFormState.status)}
                        helperText={discountErrorMessages[discountFormState.status] ?? undefined}
                        onChange={(e) => onDiscountChange(e.target.value)}
                    />
                    {(discountFormState.status === 'loading' || discountFormState.code.length === 0)
                        ? <DiscDisabledButton size='small' variant='outlined' disabled><Trans>Apply</Trans></DiscDisabledButton>
                        : <DiscApplyButton size='small' color='primary' variant='contained' onClick={onDiscountSubmit}><Trans>Apply</Trans></DiscApplyButton>
                    }
                    
                </DiscountForm>
            )}

            <OrderReceipt
                products={products}
                bundles={bundles}
                event={event}
                paidTransaction={paidTransaction}
                discount={discount}
            />
                    
            <Box textAlign='center' mt='1rem' mb='1rem'><Trans>All sales are final</Trans></Box>

            {
                errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>
            }
            <Box display='flex' justifyContent='center' width={1} mt='1rem' mb='1rem'>
                <PurchaseButton variant='contained' onClick={onClick} disabled={disablePurchase}>
                    {
                        disablePurchase ? (<PurchaseSpinner size={24} />) : t`Purchase`
                    }
                </PurchaseButton>
            </Box>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    inputRoot: {
      '& fieldset': {
        borderColor: theme.palette.success.main,
      }
    },
    inputLabelRoot: {
        color: theme.palette.success.main,
    },
    formHelperTextRoot: {
        color: theme.palette.success.main,
    },
  }));

const DiscountForm = styled(Paper)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: theme.spacing(9),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: theme.spacing(2),
}));

const DiscApplyButton = styled(Button)(({ theme }) => ({
    height: theme.spacing(5),
    marginLeft: theme.spacing(1)
}));
const DiscDisabledButton = styled(Button)(({ theme }) => ({
    height: theme.spacing(5),
    marginLeft: theme.spacing(1)
}));
const DiscRemoveButton = styled(Button)(({ theme }) => ({
    height: theme.spacing(5),
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    marginLeft: theme.spacing(1)
}));

const PurchaseButton = styled(Button)(({ theme }) => ({
    width: '50%',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: theme.palette.primary.main
    }
}));

const PurchaseSpinner = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.primary.main
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
    textAlign: 'center'
}));