
// We set these metadata fields so that google crawlers can gather info on each event's page
// Google can then show event details + links to buy tix directly on their search results

export function setStructuredData(event) {

    const script = document.createElement('script');

    script.id = 'structured-data';
    script.type = 'application/ld+json';


    script.textContent = JSON.stringify({
        // Required fields
        "@context": "https://schema.org",
        "@type": "Event",
        "name": event.title,
        "startDate": event.startDate, // We can include the timezone in this date. If not, google will assume the tz of the event location
        "location": {
            "@type": "Place",
            "name": event.venue,
            "address": {
                "@type": "PostalAddress",
                "streetAddress": event.address,
                "addressLocality": event.city,
                "addressRegion": event.province,
                "postalCode": event.postalCode,
                "addressCountry": event.country
            }
        },

        // Optional fields
        "endDate": event.endDate, // We can include the timezone in this date. If not, google will assume the tz of the event location
        "image": event.imageUrl,
        "description": event.description,
        "organizer": {
            "@type": "Organization",
            "name": event.hostName,
            "url": event.websiteUrl || undefined
        },
        "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
        "eventStatus": "https://schema.org/EventScheduled",
    });


    if (document.getElementById('structured-data')) {
        document.getElementById('structured-data').remove();
        document.head.appendChild(script);
    } else {
        document.head.appendChild(script);
    }
}