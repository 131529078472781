import React from 'react';

// Material-UI
import { styled } from '@material-ui/core/styles';
import { Grid, Box, TextField, Typography } from '@material-ui/core';

// Utility
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { Trans, t } from '@lingui/macro';

export default function PaymentForm(props) {
    const { onFormChange } = props;
    const { ccName } = props.values;
    const { ccNumber, cvc, ccExpiry } = props.ccState;

    function handleFormChange(e) {
        onFormChange(e.target.id, e.target.value);
    }

    return (
        <Box display='flex' flexDirection='column' pb={2}>
            <PurchaseHeader><Trans>Payment</Trans></PurchaseHeader>
            <PurchaseFinePrint variant='caption'><Trans>All transactions are secure and encrypted. Credit card info is not stored on our servers.</Trans></PurchaseFinePrint>

            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Box border='1px solid' borderColor={ccNumber.error ? '#f44336' : 'rgba(0, 0, 0, 0.23)'} borderRadius='4px' py='18.5px' px='14px'>
                        <CardNumberElement
                            options={{
                                placeholder: t`Credit Card Number`,
                                style: {
                                    base: { fontSize: '16px', color: 'rgba(0, 0, 0, 0.54)' },
                                    empty: { fontSize: '16px', color: 'rgba(0, 0, 0, 0.54)' }
                                }
                            }}
                        />
                    </Box>
                </Grid>

                <Grid item xs={6}>
                    <Box border='1px solid' borderColor={ccExpiry.error ? '#f44336' : 'rgba(0, 0, 0, 0.23)'} borderRadius='4px' py='18.5px' px='14px'>
                        <CardExpiryElement
                            options={{
                                placeholder: t`Expiry (MM/YY)`,
                                style: {
                                    base: { fontSize: '16px', color: 'rgba(0, 0, 0, 0.54)' },
                                    empty: { fontSize: '16px', color: 'rgba(0, 0, 0, 0.54)' }
                                }
                            }}
                        />
                    </Box>
                </Grid>

                <Grid item xs={6}>
                    <Box border='1px solid' borderColor={cvc.error ? '#f44336' : 'rgba(0, 0, 0, 0.23)'} borderRadius='4px' py='18.5px' px='14px'>
                        <CardCvcElement
                            options={{
                                placeholder: t`Security Code`,
                                style: {
                                    base: { fontSize: '16px', color: 'rgba(0, 0, 0, 0.54)' },
                                    empty: { fontSize: '16px', color: 'rgba(0, 0, 0, 0.54)' }
                                }
                            }}
                        />
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <SdTextField id='ccName' label={t`Name on Card`} variant='outlined'
                        value={ccName.value} error={ccName.error} helperText={ccName.message} onChange={handleFormChange}/>
                </Grid>
            </Grid>
        </Box>
    )
}

const SdTextField = styled(TextField)({
    width: '100%'
});
SdTextField.defaultProps = { color: 'secondary' };

const PurchaseHeader = styled('h3')(({ theme }) => ({
    marginBottom: theme.spacing(2)
}));

const PurchaseFinePrint = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2)
}));