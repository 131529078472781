import React, { useState, useEffect, useReducer } from 'react';
import { Typography, Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

// Components
import CheckoutAppBar from 'components/CheckoutAppBar';
import EventHeader from 'components/EventHeader';
import ProductList from 'components/ProductList';
import Checkout from 'components/Checkout';
import PurchaseSuccess from 'components/PurchaseSuccess';
import FullScreenModal from 'components/FullScreenModal';
import AccessCodeForm from 'components/AccessCodeForm';

// Utility
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import dayjs from 'dayjs';
import * as GA4 from 'analytics/ga4';
import * as MetaPixel from 'analytics/metaPixel';



function reducer(state, action) {
    switch (action.type) {
        case 'setCode':
            return { ...state, code: action.code.replace(/ /g, ''), status: 'pending' };

        case 'deleteCode':
            return { status: 'pending', code: '' };

        case 'setStatus':
            return { ...state, status: action.status };
        
        default:
            throw new Error();
    }
}



export default function Storefront(props) {
    // Props
    const { event, products, bundles, accessCodes, accessCodesEnabled, onAccessCodeSubmit } = props;
    
    // State
    const [state, setState] = useState('products'); // products, checkout, confirmation
    const [productCart, setProductCart] = useState(products);
    const [bundleCart, setBundleCart] = useState(bundles);
    const [email, setEmail] = useState(null); // Email to send tickts to. Set after purchase, viewed in PurchaseSuccess page
    const [discount, setDiscount] = useState(null); // Discount applied to purchase. Set after purchase, viewed in PurchaseSuccess page
    const [ticketCount, setTicketCount] = useState(null); // PDF tickets in dataURI form. Set after successful purchase. Can be downloaded from PurchaseSuccess page
    const [tranId, setTranId] = useState(null); // ID of successful transaction. Used to make a ticket_activty log entry when customer downloads tickts
    const [appleWalletURL, setAppleWalletURL] = useState(null);
    const [googleWalletURL, setGoogleWalletURL] = useState(null);

    // Discount Code Form State
    const [discountFormState, discountDispatch] = useReducer(reducer, {
        status: 'pending', // pending, error, invalid, loading, success
        code: ''
    });

    const { i18n } = useLingui();


    // Reset scroll position to top after component renders
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);    
    
    // Analytics - Log page views
    useEffect(() => {
        // Hardcode the path since users still get here from the old URL scheme where event id is passed in via query parameter on the root url. (Ex: https://frontdoor.plus/?e=123)
        switch (state) {
            case 'products':
                GA4.pageView(`Event: ${event.title}`, `/event/${event.eventId}`);
                break;
            case 'checkout':
                GA4.pageView(`Checkout: ${event.title}`, `/event/${event.eventId}`);
                break;
            case 'confirmation':
                GA4.pageView(`Purchase: ${event.title}`, `/event/${event.eventId}`);
                break;
            default:
                break;
        }
    }, [state, event]);


    const handleCheckoutOpen = () => {
        setState('checkout');
        if (event.metaPixelId) {
            MetaPixel.initiateCheckout(event.metaPixelId);
        }
    }

    const handleCheckoutClose = () => setState('products');

    const handleAddProduct = (id) => {
        setProductCart(productCart.map(product => {
            
            if (product.id === id) {
                // match
                if (product.qty < product.minPurchase) { // Bump qty up to minPurchase
                    return { ...product, qty: product.minPurchase };
                }
                if (product.qty < product.maxPurchase && product.qty < product.available) { // Bump qty up by 1
                    return { ...product, qty: (product.qty + 1) };
                }
            }
            
            // no match or max qty reached
            return product;

        }));
    };

    const handleRemoveProduct = (id) => {
        setProductCart(productCart.map(product => {

            if (product.id === id) {
                // match
                if (product.qty === product.minPurchase) { // Bump qty down to 0
                    return { ...product, qty: 0 };
                }
                if (product.qty > 0) { // Bump qty down by 1
                    return { ...product, qty: (product.qty - 1) };
                }
            }

            // no match or already at 0 qty
            return product;

        }));
    };

    const handleAddBundle = (id) => {
        setBundleCart(bundleCart.map(bundle => {
            
            if (bundle.id === id) {
                // match
                if (bundle.qty < bundle.minPurchase) { // Bump qty up to minPurchase
                    return { ...bundle, qty: bundle.minPurchase };
                }
                if (bundle.qty < bundle.maxPurchase && bundle.qty < bundle.available) { // Bump qty up by 1
                    return { ...bundle, qty: (bundle.qty + 1) };
                }
            }
            
            // no match or max qty reached
            return bundle;

        }));
    };

    const handleRemoveBundle = (id) => {
        setBundleCart(bundleCart.map(bundle => {

            if (bundle.id === id) {
                // match
                if (bundle.qty === bundle.minPurchase) { // Bump qty down to 0
                    return { ...bundle, qty: 0 };
                }
                if (bundle.qty > 0) { // Bump qty down by 1
                    return { ...bundle, qty: (bundle.qty - 1) };
                }
            }

            // no match or already at 0 qty
            return bundle;

        }));
    };

    const handlePurchase = (email, discount, ticketCount, tranId, appleWalletURL, googleWalletURL) => {
        setState('confirmation');
        setEmail(email);
        setDiscount(discount);
        setTicketCount(ticketCount);
        setTranId(tranId);
        setAppleWalletURL(appleWalletURL);
        setGoogleWalletURL(googleWalletURL);
        discountDispatch({ type: 'deleteCode' });
    }


    // Format event date range
    // Note: replacing yyyy-mm-dd with yyyy/mm/dd. Safari does not recognize the original format

    let dateString = null

    const start = dayjs(event.startDate);
    const end = dayjs(event.endDate);

    if (start.isSame(end, 'day') || end.diff(start, 'hour') < 18) {
        // Single Day event
        dateString = i18n.date(event.startDate.replace(/-/g, "/"), { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit' });
    } else {
        // Multi Day event
        dateString = i18n.date(event.startDate.replace(/-/g, "/"), { month: 'short', day: 'numeric' }) + ' - ' + i18n.date(event.endDate.replace(/-/g, "/"), { month: 'short', day: 'numeric', year: 'numeric' });
    }

    // Calculate subtotal and number of products in cart.
    const allItems = productCart.concat(bundleCart);
    let subTotal = 0;
    let emptyCart = true;
    let paidTransaction = false;
    let showDiscountForm = false;
    
    for (const i of allItems) {
        subTotal += (i.qty * i.price);

        if (i.qty > 0) {
            emptyCart = false;

            if (i.price > 0) {
                paidTransaction = true;
                showDiscountForm = true;
            }
        }
    }

    if (discount && paidTransaction) { // set paidTransaction back to false if a discount code brought the price down to $0
        let discountTotal = (subTotal * discount.percent) + discount.amount;
        if (discountTotal >= subTotal) paidTransaction = false;
    }


    if (state === 'confirmation') {
        const continueShopping = () => {
            setState('products');
            setProductCart(products);
            setBundleCart(bundles);
            setEmail(null);
            setDiscount(null);
            setTicketCount(null);
            setTranId(null);
            setAppleWalletURL(null);
            setGoogleWalletURL(null);
        }

        return (
            <PurchaseSuccess
                email={email}
                event={event}
                ticketCount={ticketCount}
                paidTransaction={paidTransaction}
                products={productCart}
                bundles={bundleCart}
                discount={discount}
                tranId={tranId}
                appleWalletURL={appleWalletURL}
                googleWalletURL={googleWalletURL}
                onReturn={continueShopping}
            />
        )
    }

    return (
        <div>
            <CheckoutAppBar subTotal={subTotal} onClick={handleCheckoutOpen} disabled={emptyCart || event.chargesEnabled === 0} />

            {event.chargesEnabled === 0 && (
                <Box paddingTop={[2,4]} paddingX={[2,4]}>
                    <Alert severity='info' variant='filled'><Trans>Tickets will be available for sale soon. Please check back later.</Trans></Alert>      
                </Box>
            )}

            <EventHeader
                title={event.title}
                hostName={event.hostName}
                hostId={event.hostId}
                date={dateString}
                concluded={event.concluded}
                address={`${event.address} - ${event.city}, ${event.province} ${event.postalCode}`}
                description={event.description}
                imageUrl={event.imageUrl || undefined}
                websiteUrl={event.websiteUrl}
                facebookUrl={event.facebookUrl}
                twitterUrl={event.twitterUrl}
                instagramUrl={event.instagramUrl}
                tiktokUrl={event.tiktokUrl}
            />

            {event.concluded === 1 ? (
                <Typography variant='h5' style={{textAlign: 'center', marginBottom: 150}}><Trans>The event is over, see you again soon.</Trans></Typography>
            ) : (
                <>
                    <ProductList
                        title={event.title}
                        products={productCart}
                        bundles={bundleCart}
                        chargesEnabled={event.chargesEnabled}
                        onAddProduct={handleAddProduct}
                        onRemoveProduct={handleRemoveProduct}
                        onAddBundle={handleAddBundle}
                        onRemoveBundle={handleRemoveBundle}
                    />
                    {(products.length === 0 && bundles.length === 0) && <Typography variant='h5' style={{textAlign: 'center', marginBottom: 150}}><Trans>Tickets aren't available to purchase at the moment.</Trans></Typography>}
                </>
            )}

            {accessCodesEnabled === true && (
                <AccessCodeForm
                    eventId={event.eventId}
                    accessCodes={accessCodes}
                    onSubmit={onAccessCodeSubmit}
                />
            )}

            <FullScreenModal title={t`Checkout`} open={state === 'checkout'} onClose={handleCheckoutClose}>
                {state === 'checkout' && (
                    <Checkout
                        products={productCart}
                        bundles={bundleCart}
                        event={event}
                        showDiscountForm={showDiscountForm}
                        paidTransaction={paidTransaction}
                        discount={discount}
                        discountFormState={discountFormState}
                        discountDispatch={discountDispatch}
                        onDiscountChange={setDiscount}
                        accessCodes={accessCodes}
                        onPurchase={handlePurchase}
                        onCancel={handleCheckoutClose}
                    />
                )}
            </FullScreenModal>
        </div>
    )
}